import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useHistory, useLocation} from "react-router";
import { Link } from 'react-router-dom';
import { Container } from "@material-ui/core";
import CI from "../ci.png";
import {useTranslation} from "react-i18next";
import {Tooltip} from "@mui/material";
const BarWidth = 40;

const Root = styled.div`
  width: 100vw;
  height: 105px;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  z-index: 1000;
  &:not(.noBg) {
    background-color: rgba(255,255,255,1);
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
`;

const LangBtn = styled.div`
  cursor: pointer;
  border-radius:45%;
  font-weight:600;
  font-size:1rem;
  width:40px;
  text-align:center;

  :hover{
    background: rgba(240,255,252,0.8);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
    
  }
`

const LogoSection = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  & > img {
    width: inherit;
    height: 2rem;
    margin: 1rem;
  }
  & > h1 {
    font-size: 2rem;
    color: ${props => props.theme.palette.primary.dark};
  }
`;

const MenuSection = styled.div`
  flex: 1 1 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & a {
    text-decoration: none;
  }
`;

const MenuWrapper = styled.div`
  padding: 1.5rem 3rem;
  & > p {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
  }
  &.active > p {
    font-weight: 800;
    color: ${props => props.theme.palette.secondary.main};
  }
`;

const MenuActiveBar = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  transition: left 200ms ease-in-out, opacity 600ms ease-in-out;
  height: 6px;
  width: ${BarWidth}px;
  background-color: ${props => props.theme.palette.secondary.main};
`;



interface MenuProps {
  name: string;
  path: string;
  active?: boolean;
  onMouseOver: (e: React.MouseEvent) => void;
  onActive: (elem: HTMLElement) => void;
}

const Menu = React.memo((props: MenuProps) => {

  const { name, active, onActive, onMouseOver } = props;
  const ref = React.useRef<any>();

  React.useEffect(() => {
    if (active && ref.current) {
      onActive(ref.current);
    }
  }, [active, onActive]);

  return (
    <MenuWrapper
      ref={ref}
      className={active ? 'active' : ''}
      onMouseOver={onMouseOver}
    >
      <p>{name}</p>
    </MenuWrapper>
  );
});


const menus = [
  {
    name: 'About VODA',
    path: '/'
  },
  {
    name: 'Service',
    path: '/#service'
  },
  // {
  //   name: 'Demo',
  //   path: '/demo'
  // },
  {
    name: 'Contact Us',
    path: '/contact'
  },
  {
    name: 'Career',
    path: 'https://www.saramin.co.kr/zf_user/company-info/view-inner-recruit?csn=L05Kd1RiVjhidUlBZkFFVHpTNUpPUT09'
  },

];


interface HeaderProps {
  noBg?: boolean;
  onAboutActive?: () => void;
  onServiceActive?: () => void;
  inAbout?: boolean;
  inService?: boolean;
}


export default function Header(props: HeaderProps) {
  const { noBg, onAboutActive, onServiceActive, inAbout, inService } = props;
  const location = useLocation();
  const [opacity, setOpacity] = React.useState(0);
  const [left, setLeft] = React.useState(850);
  const ref = React.useRef<any>();
  const history = useHistory();
  const {t} = useTranslation();
  const updateLocation = React.useCallback((elem: HTMLElement) => {
    const start = ref.current ? ref.current.getBoundingClientRect().left : 0;
    const { left, right } = elem.getBoundingClientRect();
    setLeft((right + left) / 2 - start - BarWidth / 2);
    setOpacity(1);
  }, []);

  const {i18n} = useTranslation();
  const [, setLangBtn] = useState(false)

  useEffect(() => {
    if(i18n.language === 'en') {
      i18n.changeLanguage('en-US')
      setLangBtn(true)
    }

  }, [i18n])

  const onChangeKO = () => {
    i18n.changeLanguage('ko-KR')
    setLangBtn(false)
  }
  const onChangeEN = () => {
    i18n.changeLanguage('en-US')
    setLangBtn(true)
  }

  const handleActive = React.useCallback((elem: HTMLElement) => {
    updateLocation(elem);
  }, [updateLocation]);

  const handleMouseOver = React.useCallback((e: any) => {
    updateLocation(e.currentTarget as HTMLElement);
  }, [updateLocation]);

  return (

    <Root className={ inAbout === true ? 'noBg' : ""}>
      <Container maxWidth="xl">
        <Wrapper>
          <LogoSection>
            <img src={CI} alt="logo" onClick={() => history.push('/')} />
          </LogoSection>
          <MenuSection ref={ref}>
            {menus.map(({name, path}) => (
                name === 'Career' ? (
                      <Tooltip title={t("global.career_tab")} className={'tooltip'}>
                        <a key={name} href={path} target={"_blank"} rel="noopener noreferrer">
                          <Menu
                              name={name}
                              path={path}
                              onMouseOver={handleMouseOver}
                              onActive={handleActive}
                          />
                        </a>
                      </Tooltip>
                    ) : (
                    <Link
                        key={name}
                        to={path}
                        onClick={() => {
                          if (name === 'About VODA') {
                            onAboutActive && onAboutActive();
                          } else if (name === 'Service') {
                            onServiceActive && onServiceActive();
                          }
                        }}
                    >
                      <Menu
                          name={name}
                          path={path}
                          active={
                              (name === 'About VODA' && inAbout)
                              || (name === 'Service' && inService)
                              || (!inAbout && !inService && path !== '/' && location.pathname.startsWith(path))
                          }
                          onMouseOver={handleMouseOver}
                          onActive={handleActive}
                      />
                    </Link>
                )
            ))}
            <MenuActiveBar
              style={{ left, opacity }}
            />
          </MenuSection>
          {i18n.language === 'en' ? <LangBtn  onClick={onChangeKO}>KOR</LangBtn> : i18n.language === 'en-US' ?  <LangBtn  onClick={onChangeKO}>KOR</LangBtn>  : <LangBtn  onClick={onChangeEN}>ENG</LangBtn>}
        </Wrapper>
      </Container>

    </Root>
  );
}
