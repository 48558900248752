import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {  Container } from "@material-ui/core";
import Header from "../../components/Header";
import {useTranslation} from "react-i18next";
import BrochureButton from "../../components/BrochureButton";
import {useMutation, useQueryClient} from "react-query";
import axios from "axios";
import UserInfoForm from "../../components/UserInfoForm";
import {useHistory} from "react-router";
import {useSnackbar} from "notistack";
import noBrochure from "../../assets/vi_next.mp4";
import PrivacyBtn from "../../components/PrivacyBtn";

interface LeftRightProps {
    activeBrochure: boolean;
}

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 100vw;
  min-height: 100vh;
  
  & > .MuiContainer-root {
    flex: 1 1 auto;
    display: flex;
  }
`;

const Left = styled.div<LeftRightProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: auto;
  text-align: center;
  
  .no_btn{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    p {
      font-size: 15px;
    }
    
    video {
      width:40px;
      margin-bottom: 20px;
    }
  }
  .txt_o{
    color: #41c2ab;
  }

  & > h2 {
    font-size: 3rem;
  }
  & > p {
    font-size: 1.125rem;
    margin: 16px 0;
    opacity: 0.8;
  }
  & a {
    color: inherit;
    text-decoration: none;
  }
  opacity: 0;
  
  @keyframes contact-left {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 0.75;
      transform: translateY(0%);
    }
  }
  animation: contact-left .7s;
  animation-fill-mode: forwards;
  
  .emailTelBox{
    margin-bottom: 30px;
  }
`;


export function useVoCMutation() {
    const queryClient = useQueryClient();
    const history = useHistory();
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    return useMutation("voc",
        ({ company, fullname, department, position, email, job, customizeJob , number, visitorRoute, customizeRoute, userLang }: any) =>
            axios.post(
            "https://api.vodavision.ai/api/v1/subscribe",
            //     "/",
            { name:fullname,
                    job:job,
                    customize_job:customizeJob,
                    affiliation_company:company,
                    department:department,
                    position:position,
                    title:'',
                    phone_number: number,
                    email_address:email,
                    visit_route:visitorRoute,
                    customize_route:customizeRoute,
                    geographic_location:userLang,
                },
            { withCredentials: false }
        ),
        {
            onSuccess: () => {
                queryClient.resetQueries(`get-vocs`).then();
                history.push('/sendinfo')
            },
            onError: () => {
                queryClient.resetQueries(`get-vocs`).then();
                enqueueSnackbar(
                    t('contact.confirmMsg'),
                    { variant: 'error', autoHideDuration : 3000 },
                );
            },
            onMutate: () => {
                setIsLoading(true);
            },
            onSettled: () => {
                setIsLoading(false);
            },
        }
    );
}

export default function ContactUs() {
  const {t, i18n} = useTranslation();
  const [activeBrochure, setActiveBrochure] = useState(false);
  useEffect(() => {
      if(i18n.language === 'en-US') {
          setActiveBrochure(false)
      }

  }, [i18n.language])

  const activeBtn = () => {
      setActiveBrochure(true)
  }

  return (
    <Root>
      <Header />
      <Container maxWidth="xl">
        <Left activeBrochure={activeBrochure}>
          <h2>{t('contact.contact')}</h2>
          {
            i18n.language === 'en-US' ?
                <p>
                  {t('contact.interest')}
                  <br />
                  {t('contact.function_1')}
                  <br />
                  {t('contact.function_2')}
                  <br />
                  <strong> {t('contact.leave_1')}</strong>
                  <br />
                  {t('contact.call')}
                  <br />
                  <br />
                  {t('contact.contents_1')}
                </p>
                :
                <p>
                  <strong>V<span className={'txt_o'}>O</span>DA</strong> {t('contact.interest')}
                  <br />
                  <strong>{t('contact.function')}</strong>{t('contact.leave')}
                  <br />
                  {t('contact.call')}
                  <br />
                  <br />
                  {t('contact.contents_1')}
                </p>

          }

          <p className={'emailTelBox'}>
            <strong>E-mail:</strong> <a href="mailto:contact@vodiavision.ai">contact@vodavision.ai</a>
            <br />
            <strong>Tel:</strong> 02-1661-4078
          </p>
            {
                i18n.language === 'en-US' ?
                    <div className={'no_btn'}>
                        <video loop autoPlay muted poster="aaa" preload="auto">
                            <source src={noBrochure} type="video/mp4" />
                        </video>
                        <p>
                            <div>Currently, our brochure is only available in Korean.</div>
                            <div>If you tell us who you are at <strong>cmo@vodavision.ai</strong>,</div>
                            <div>we will send you the brochure as soon as available!</div>
                        </p>

                    </div>
                    :
           <BrochureButton msg={t("contact.send_brochure")} activeBtn={activeBtn} mode={'pc'}/>
            }
            <PrivacyBtn name={t('global.voda_privacy')} link={'/privacy'} target={'_blank'} top={'5px'}/>
        </Left>
          {activeBrochure && <UserInfoForm mode={'pc'} close={activeBtn} />}
      </Container>



    </Root>
  );
}
