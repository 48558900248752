import React from 'react';
import styled from "styled-components";
import Header from "../../components/Header";

const Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100vw;
  min-height: 100vh;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > h1, & > h2, & > h3 {
    margin: 0;
  }
  & > h1 > span {
    color: #333;
    text-shadow: -8px 0 0 #fff;
    font-size: 252px;
    font-weight: 800;
    letter-spacing: -40px;
    line-height: 1;
    opacity: 0;
    @keyframes header1 {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    animation: header1 0.7s;
    animation-fill-mode: forwards;
  }
  & > h1 {
    transform: translateX(-20px);
  }
  & > h2 {
    font-weight: 400;
    font-size: 20px;
    opacity: 0;
    @keyframes header2 {
      from {
        opacity: 0;
        transform: translateY(10%);
      }
      to {
        opacity: 1;
        transform: translateY(0%);
      }
    }
    animation: header2 0.7s;
    animation-delay: 0.4s;
    animation-fill-mode: forwards;
  }
`;


export default function NotFound() {
  return (
    <Root>
      <Header />

      <Wrapper>
        <h1>
          <span>4</span>
          <span>0</span>
          <span>4</span>
        </h1>
        <h2>죄송합니다, 요청하시는 페이지를 찾을 수 없습니다.</h2>
      </Wrapper>
    </Root>
  );
}