import React from 'react';
import {Link} from "react-router-dom";

const PrivacyBtn = ({...props}) => {
    const {name, link, target, top} = props;
    return (
        <Link to={link} target={target} style={{ textDecoration: "none" }}>
            <div style={{color:"gray", fontSize:"0.5rem", marginTop:top}}>{name}</div>
        </Link>
    );
};

export default PrivacyBtn;




