import React from 'react';
import blueCheck from "../mobilesvg/blue-check-mark-icon.svg";
import part1 from "../mobilesvg/part1.png";
import newArrow from "../mobilesvg/newArrow.svg";
import part2 from "../mobilesvg/part2.png";
import bolt1 from "../mobilesvg/bolt1.png";
import bolt2 from "../mobilesvg/bolt2.png";
import bolt4 from "../mobilesvg/bolt4.png";
import bolt3 from "../mobilesvg/bolt3.png";
import {MobileTitleBar, Wrapper, Arrow} from "../index";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const MobileApplication = () => {
    const {t, i18n} = useTranslation();
    return (
        <div>

            <MobileTitleBar>
                <div className={'grayBox'} id={'left'}>
                    <div className={'textBox'} >

                        {
                            i18n.language === 'en-US' ?
                                <>
                                    <span className={'blue'}>A</span>pplication  <span className={'blue'}>C</span>ases
                                </>
                                :
                                <>
                                    적용 사례
                                </>
                        }
                        
                    </div>
                    <div className={'blackMiniBox'}></div>
                </div>
            </MobileTitleBar>

            <Wrapper>
                <ApplicationCasesStyled>
                    <div className={'titleBox'}>
                        <div className={'blueBox'}> </div>
                        <div className={'grayBox'}>{t('mo.fastening') || ""}</div>
                    </div>

                    <div className={'contentsBox'}>
                        <div className={'contentsTextBox'}>
                            <div><img src={blueCheck} alt={'mark'} /></div>
                            <div className={'contentsText'}>
                                {t('mo.environment_1') || ""}<br/>
                                {t('mo.environment_2') || ""}
                            </div>
                        </div>

                        <div className={'contentsTextBox'}>
                            <div><img src={blueCheck} alt={'mark'}/></div>
                            <div className={'contentsText'}>{t('mo.duplicate') || ""} </div>
                        </div>
                    </div>

                    <div className={'checkImgBox'}>
                        <div>
                            <img src={part1} alt={'part1'} className={'part'}/>
                            <div className={'imgTitle'}>{t('mo.existing') || ""}</div>
                        </div>
                        <div>
                            <img src={newArrow} alt={'arrow'} className={'arrow'}/>
                        </div>
                        <div>
                            <img src={part2} alt={'part2'} className={'part'}/>
                            <div  className={'imgTitle'}>{t('mo.after') || ""}</div>
                        </div>
                    </div>

                    <div className={'resultBox'}>
                        <div className={'resultTitle'}>{t('mo.accuracy') || ""}</div>

                        <div className={'resultPerBox'}>
                            <div className={'beforePer'}>96%</div>
                            <Arrow>
                                <div className="arrow-1" id={'grayArrow'}/>
                            </Arrow>
                            <div className={'resultPer'}>99.8%</div>
                        </div>

                    </div>
                </ApplicationCasesStyled>

                <ApplicationCasesStyled>
                    <div className={'titleBox'}>
                        <div className={'blueBox'}> </div>
                        <div className={'grayBox'}>{t('mo.shape') || ""}</div>
                    </div>

                    <div className={'contentsBox'}>
                        <div className={'contentsTextBox'}>
                            <div><img src={blueCheck} alt={'mark'} /></div>
                            <div className={'contentsText'}>
                                {t('mo.added') || ""}
                            </div>
                        </div>

                        <div className={'contentsTextBox'}>
                            <div><img src={blueCheck} alt={'mark'}/></div>
                            <div className={'contentsText'}>{t('mo.speed') || ""} </div>
                        </div>
                    </div>

                    <div className={'checkImgBox'}>
                        <div>
                            <img src={bolt1} alt={'bolt1'} className={'part'}/>
                        </div>
                        <div>
                            <img src={newArrow} alt={'arrow'} className={'arrow'}/>
                        </div>
                        <div>
                            <img src={bolt2} alt={'bolt2'} className={'part'}/>
                        </div>
                    </div>
                    <div className={'test'}>
                        <img src={newArrow} alt={'arrow'} className={'arrowBottom'}/>
                    </div>

                    <div className={'checkImgBox'}>
                        <div>
                            <img src={bolt4} alt={'bolt1'} className={'part'}/>
                        </div>
                        <div>
                            <img src={newArrow} alt={'arrow'} className={'arrowLeft'}/>
                        </div>

                        <div>
                            <img src={bolt3} alt={'bolt1'} className={'part'}/>
                        </div>
                    </div>

                    <div className={'resultBox_twice'}>
                        <div className={'resultTitle'}> {t('mo.insAccuracy') || ""}</div>
                        <div className={'resultPerBox'}>
                            <div className={'beforePer'}>91.37%</div>
                            <Arrow>
                                <div className="arrow-1" id={'grayArrow'}/>
                            </Arrow>
                            <div className={'resultPer'}>99.86%</div>
                        </div>
                    </div>
                </ApplicationCasesStyled>
            </Wrapper>
        </div>
    );
};

export default MobileApplication;

const ApplicationCasesStyled = styled.div`
    .titleBox{
      display: flex;
      margin: 2.2rem auto 0;
      border-radius: 1px;
      align-items: center;
      font-size: 1.2rem;
      width:17rem;
      height: 2.2rem;
      font-weight: 500;
      background-color: #f2f2f2;
      
      .blueBox{
        background-color: #92f1df;
        width:1rem;
        height: 2.2rem;

      }
      .grayBox{
        margin:0 auto;
      }
    }
  .contentsBox{
    display: flex;
    flex-direction: column;
    margin:2rem auto;
    max-width: 90%;
    align-items: center;
    text-align: center;
    .contentsTextBox {
      display: flex;
      margin:0.7rem 0;
      text-align: center;
      
      .contentsText{
        word-break:normal;
      }
      img{
        margin-top: 0.2rem;
        margin-right: 1rem;
        width: 1.1rem;
        background: rgb(218,243,239);
      }
    }
    
  }
  .test{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-left: -16%;
    .arrowBottom{
      width: 1rem;
      display: flex;
      position: relative;
      margin:0.5rem;
    }
  }
  
  .checkImgBox{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    .arrow{
      transform:rotate(-90deg);
      width: 1rem;
      margin:0 0.5rem;
}
    .arrowLeft{
      transform:rotate(90deg);
      width: 1rem;
      margin:0 0.5rem;
    }
   
    .part{
      width: 9rem;
    }
    .imgTitle{
      color:#4f4f4f;
      text-align: center;
    }
  }

  .resultBox{
    width: 90%;
    align-content: center;
    align-items: center;
    font-weight: 600;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 5rem auto;
    border: 1px solid rgba(87,218,184, 0.8);
    border-radius: 10px;
    box-shadow: 2px 5px 5px lightgray;
    background-color: rgba(87,218,184, 0.1);
    
    .resultTitle{
      color:#4f4f4f;
      font-size: 1.0rem;
      margin-top: 1rem;
    }
    .beforePer{
      color:#4f4f4f;
      font-size: 1.1rem;
      margin: 0 1rem;
    }
    .resultPerBox{
      display: flex;
      align-content: center;
      align-items: center;
      margin: 0 0 0.5rem 0;
    }
    .resultPer{
      font-size: 1.7rem;
      color:#44ae90;
      margin-left: 1rem;
    }
  .arrowRight{
    width: 5rem;
  }
  }
  
  .resultBox_twice{
    width: 100%;
    padding: 0 1rem;
    box-sizing: border-box;
    align-content: center;
    align-items: center;
    font-weight: 600;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 5rem auto;
    border: 1px solid rgba(87,218,184, 0.8);
    border-radius: 10px;
    box-shadow: 2px 5px 5px lightgray;
    background-color: rgba(87,218,184, 0.1);

    .resultTitle{
      color:#4f4f4f;
      font-size: 1.0rem;
      margin-top: 1rem;
    }
    .beforePer{
      color:#4f4f4f;
      font-size: 0.9rem;
      margin: 0 1rem;
    }
    .resultPerBox{
      display: flex;
      align-content: center;
      align-items: center;
      margin: 0 0 0.5rem 0;
    }
    .resultPer{
      font-size: 1.3rem;
      color:#44ae90;
      margin-left: 1rem;
    }
    .arrowRight{
      width: 5rem;
    }
  }
  .checkImgBox{
    display: flex;
  }
`