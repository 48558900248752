import React from 'react';
import {MobileTitleBar, Wrapper} from "../index";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import ReactPlayer from "react-player";
import {videoURL_ko, videoURL_en} from "../../VideoLink";

const MobileVideo = () => {
    const {t, i18n} = useTranslation();
    return (
        <MobileVideoStyle>
            <MobileTitleBar>
                <div className={'grayBox'} id={'left'}>
                    <div className={'textBox'} >

                        {
                            i18n.language === 'en-US' ?
                                <>
                                   <span className={'bluetxt'}>I</span>{t("mo.video1")}{" "}
                                    <span className={'bluetxt'}>V</span>{t("mo.video2")}
                                </>
                                :
                                <>
                                    {t("mo.video")}
                                </>
                        }
                    </div>
                    <div className={'blackMiniBox'}></div>
                </div>
            </MobileTitleBar>

            <Wrapper>
                <ApplicationCasesStyled>
                    <div className='player-wrapper'>
                        <ReactPlayer
                            url={i18n.language === 'en-US' ? videoURL_en : videoURL_ko}
                            className='react-player'
                            controls={true}
                            muted={true}
                            width='100%'
                            height='100%'
                        />
                    </div>

                </ ApplicationCasesStyled>
            </Wrapper>
        </MobileVideoStyle>
    );
};

export default MobileVideo;

const MobileVideoStyle = styled.div`
  .bluetxt{
 color: #23b99e;
}

`

const ApplicationCasesStyled = styled.div`
  padding:10px;
  width:100vw;

  .player-wrapper {
    position: relative;
    padding-top: 56.25%;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
  
`
