import ko from './ko';
import en from './en';

const locales = {
  'ko-KR': {
    translation: ko
  },
  'en-US': {
    translation: en,
  }
}

export default locales;