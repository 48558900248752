import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import Logo from '../../ci.png';
import MobileAbout from "./mobilePage/MobileAbout";
import MobileService from "./mobilePage/MobileService";
import MobileApplication from "./mobilePage/MobileApplication";
import MobileWith from "./mobilePage/MobileWith";
import ko from "../MobileMaintenance/mobilesvg/flag-for-south-korea-svgrepo-com.svg"
import us from "../MobileMaintenance/mobilesvg/us.svg"
import {useTranslation} from "react-i18next";import BrochureButton from "../../components/BrochureButton";
import UserInfoForm from "../../components/UserInfoForm";
import {Dialog} from "@mui/material";
import MobileVideo from "./mobilePage/MobileVideo";
import noBrochure from "../../assets/vi_next.mp4";
import MoPrivactDialog from "../../components/MoPrivactDialog";

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
`;

const LangSection = styled.div`
    position: absolute;
    right: 3rem;
    top:2rem;
  //img {
  //  width: 2rem;
  //}
`
const LogoSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & > img {
    width:inherit;
    height: 2.5rem;
    margin: 2rem auto 0;
  }
  & > h1 {
    font-size: 3rem;
    color: ${props => props.theme.palette.primary.dark};
  }
  transform: translateX(-1rem);
`;

export const Wrapper = styled.div`
  
  display: flex;
  flex-direction: column;
  align-items: center;
  & > h1, & > h2, & > h3 {
    margin: 0;
    text-align: center;
  }
  &  {
    max-width: 100vw;
    opacity: 0;
    @keyframes ima {
      from {
        opacity: 0;
        transform: translateY(-5%);
      }
      to {
        opacity: 1;
        transform: translateY(0%);
      }
    }
    animation: ima 1s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
  }
  & > h1 {
    opacity: 0;
    font-size: 2rem;
    @keyframes header1 {
      from {
        opacity: 0;
        transform: translateY(10%);
      }
      to {
        opacity: 1;
        transform: translateY(0%);
      }
    }
    animation: header1 0.7s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
  }
  & > h2 {
    margin-top: 2rem;
    font-size: 1rem;
    font-weight: 400;
    opacity: 0;
    @keyframes header2 {
      from {
        opacity: 0;
        transform: translateY(10%);
      }
      to {
        opacity: 1;
        transform: translateY(0%);
      }
    }
    animation: header2 0.7s;
    animation-delay: 0.6s;
    animation-fill-mode: forwards;
    & > strong {
      color: ${props => props.theme.palette.secondary.main};
    }
  }
`;

const Spacer = styled.div`
  flex: 1 1 auto;
`;

const Contact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
  color: #333;
  .no_btn{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    video {
      width:40px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  
  
  & > a {
    color: #333;
    text-decoration: none;
  }
  .profile {
    background-color: rgba(87,218,184,0.5);
    margin-bottom: 1rem;
    padding: 0.3rem 0.5rem;
    border-radius: 0.2rem;
  }
  .bottomText{
    margin-top: 0.5rem;
    text-align: center;
  }
  
`;

const Bar = styled.div`
  width: 2rem;
  height: 1px;
  background-color: #333;
  margin: 1rem 0;
`;

export const MobileTitleBar = styled.div`
  @keyframes ima {
    from {
      opacity: 0;
      transform: translateY(-5%);
    }
    to {
      opacity: 1;
      transform: translateY(0%);
    }
  }
  animation: ima 1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  .grayBox{
    width: 20rem;
    height: 3rem;
    background-color: #ececec;
    position: relative;
    display: flex;
    align-items: center;
    margin: 3rem 0 1rem;
    
  .textBox{
    font-size: 1.6rem;
    font-weight: 600;
    margin: auto;
      .vodaText{
        font-size: 1.7rem;
    }
    .blue{
      color: #23b99e;
    }
  }
  .blackMiniBox {
    position: absolute;
    left: 18rem;
    top: 0.5rem;
    width: 3.8rem;
    height: 0.6rem;
    background-color: rgba(64,64,64,0.8);
  } }
  
  #grayfirst{
    margin: 3rem 0 2rem;
  }

  #right{
    transform:rotate(180deg);
    margin-left: auto;
    .textBox{
      transform:rotate(-180deg);
    }
  }
`

export const FunctionBoxStyled = styled.div`
    display: flex;
    width: 95vw;
    justify-content: space-around;
    margin-top: 3rem;
  
  .funcBox{
    width: 9rem;
    height: 15rem;
    background:#c7efe1;
    text-align: center;
    
    .imageBox{
      width: 6rem;
      height: 7rem;
      background: rgba(255,255,255,0.5);
      margin:0.7rem auto;
      img{
        width: 4rem;
        margin-top: 1.5rem;
      }
    }
    
    .title{
      color: #23b99e;
      font-weight: 600;
      font-size: 1.1rem;
    }
    .line{
      width: 3rem;
      background: rgb(255,255,255);
      height: 0.1rem;
      margin: 0.3rem auto;
    }
    .contents{
      font-size: 0.9rem;
      padding: 3px;
    }
  }

`
export const Arrow = styled.div`
  
  #grayArrow:before{
    background: #bfbfbf;
  }
  .arrow-1 {
    width:110px;
    height:30px;
    display: flex;
    margin: 0.6rem 0;
  }
  .arrow-1:before {
    content: "";
    background: rgba(35,185,158, 0.8);
    width:130px;
    //이곳 임시 변경 움직임 때문에 
    clip-path: polygon(0 10px,calc(100% - 15px) 10px,calc(100% - 15px) 0,100% 50%,calc(100% - 15px) 100%,calc(100% - 15px) calc(100% - 10px),0 calc(100% - 10px));
    animation: a1 1.5s infinite linear;

  }
  @keyframes a1 {
    90%,100%{flex-grow: 1}
  }
  #twiceArrowCurb{
  transform: rotate(-135deg);
  }
  #arrowBackCurb{
    transform: rotate(45deg);
  }
  #twiceArrowCurbB{
    transform: rotate(135deg);
  }
`

const TrialStyle = styled.div`
  width: 90%;
  margin: auto;
  .title{
    font-size: 0.8rem;
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 2rem;
    border-bottom: 2px solid #57dab8;
    border-top: 2px solid #57dab8;
    background:rgba(199,239,225,0.3) ;
    padding: 1rem;

  }
`

export default function MobileMaintenance() {

    const {t, i18n} = useTranslation();
    const [, setLangBtn] = useState(false);

    const [activeBrochure, setActiveBrochure] = useState(false);
    const [privacyActive, setPrivacyActive] = useState(false);

    const activeprivacyPopupHandler = () => {
        setPrivacyActive(false)
    }

    const activeBtn = () => {
        setActiveBrochure(true)
    }

    const handleClose = () => {
        setActiveBrochure(false);
    };


    useEffect(() => {
        if(i18n.language === 'en') {
            i18n.changeLanguage('en-US')
            setLangBtn(true)
        }

    }, [i18n])
    const onChangeKO = () => {
        i18n.changeLanguage('ko-KR')
        setLangBtn(false)
    }
    const onChangeEN = () => {
        i18n.changeLanguage('en-US')
        setLangBtn(true)
    }

    return (
        <Root>
            <LogoSection>
                <img src={Logo} alt="logo" />
            </LogoSection>
            <TrialStyle>
                {
                    i18n.language === 'en-US' ?
                        <p className={'title'}>
                            You are on the trial page.
                            <br/>
                            We are currently developing our mobile page.
                            <br/>
                            For more information, please visit on desktop.</p>
                        :
                        <p className={'title'}>현재 보시고 있는 페이지는 데모 버전 입니다.
                            <br/>
                            모바일페이지 개발 중입니다.
                            <br/>
                            더 상세한 내용은 데스크탑을 이용해주세요</p>
                }
            </TrialStyle>
            <LangSection>
                {i18n.language === 'en' ? <img src={ko} alt={'ko'} onClick={onChangeKO} width={'20px'}/> : i18n.language === 'en-US' ?  <img src={ko} alt={'ko'} onClick={onChangeKO} width={'20px'}/>  : <img src={us} alt={'us'} onClick={onChangeEN} width={'20px'}/>}
            </LangSection>
            <MobileAbout />
            <MobileService />
            <MobileApplication />
            <MobileWith />
            <MobileVideo />
            <Spacer />
            <Contact>
                <Bar />
                <h3>{t('contact.contact')}</h3>
                <span>+82 02-1661-4078</span>
                <a href="mailto:contact@vodavision.ai">contact@vodavision.ai</a>
                {
                    i18n.language === 'en-US' ?
                        <div className={'no_btn'}>
                            <video loop autoPlay muted poster="aaa" preload="auto">
                                <source src={noBrochure} type="video/mp4" />
                            </video>
                            <p>
                                <div>Currently, our brochure is only available in Korean.</div>
                                <div>If you tell us who you are at <strong>cmo@vodavision.ai</strong>,</div>
                                <div>we will send you the brochure as soon as available!</div>
                            </p>

                        </div>
                        :
                         <BrochureButton msg={t("contact.send_brochure")} activeBtn={activeBtn} mode={'mobile'}/>
                }


                <Bar />
                <div className={'bottomText'}>
                    <div>{t('footer.company_name')} | {t('footer.ceo')} : 전창연</div>
                    <div>{t('footer.address')}</div>
                    <div>{t('footer.registration')}: 823-87-02509</div>
                    <br/>
                    <div style={{color:"gray", fontSize:"0.7rem"}}>Copyright © VODA AI Inc. All Rights Reserved.</div>
                    <div style={{color:"gray", fontSize:"0.5rem"}} onClick={() => {setPrivacyActive(true)}}>{t('global.voda_privacy')}</div>
                </div>
            </Contact>

                <MoPrivactDialog  open={privacyActive} onClose={activeprivacyPopupHandler} fullWidth={true}/>
                <Dialog open={activeBrochure} onClose={handleClose} fullWidth={true} >
                        <UserInfoForm mode={'mobile'} close={handleClose}/>
                </Dialog>

        </Root>
    );
}
