import React from 'react';
import Router from './components/Router';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StylesProvider } from '@material-ui/core';
import { lightTheme } from './theme';
import { SnackbarProvider } from 'notistack';


function App() {
  const theme = lightTheme;
  return (
    <StylesProvider injectFirst>
      <StyledThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <CssBaseline />
            <Router />
          </SnackbarProvider>
        </MuiThemeProvider>
      </StyledThemeProvider>
    </StylesProvider>
  );
}

export default App;
