import React from 'react';
import styled from "styled-components";

const Chart = (props:any) => {


    return (
        <ChartStyled>
            <div className="pie">
                <div className="html">30% </div>
            </div>
        </ChartStyled>

    );
};

export default Chart;


const ChartStyled = styled.div`
  
  .pie {
    margin: 5vmin auto;
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
    overflow: hidden;
    background: linear-gradient(15deg, #ececec 50%, transparent 50%), linear-gradient(to right,#ececec 50%, #23b99e 50%);
    position: relative;
  }

  .html {
   position: absolute;
    top:4rem;
    left: 3.7rem;
    font-size: 1.8rem;
    font-weight: 600;
    
  }
  
`