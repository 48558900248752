import React, {useCallback, useEffect, useState} from 'react';
import {Button, Checkbox, FormControl, FormControlLabel, InputLabel, TextField} from "@material-ui/core";
import styled from "styled-components";
import {useVoCMutation} from "../pages/ContactUs";
import PPDialog from "./PPDialog";
import {MenuItem, Select, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import MarketingDialog from "./MarketingDialog";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";

interface RowProps {
    value: string;
}

interface RightProps {
    loading: boolean;
    mode:string;
}


interface FieldStatus {
    value: string;
    isActive: boolean;
}

interface ActiveFields {
    [key: string]: FieldStatus;
}


const UserInfoForm = (mode:any, close:any) => {
    const {t, i18n} = useTranslation();
    const [agreement, setAgreement] = useState(false);
    const [marketingAgreement, setMarketingAgreement] = useState(false);
    const { mutate, isLoading } = useVoCMutation();
    const [open, setOpen] = useState(false);
    const [marketingOpen, setMarketingOpen] = useState(false);
    const [company, setCompany] = useState("");
    const [fullname, setFullname] = useState("");
    const [department, setDepartment] = useState("");
    const [position, setPosition] = useState("");
    const [email, setEmail] = useState("");
    const [job, setJob] = useState("");
    const [customizeJob, setCustomizeJob] = useState("")
    const [number, setNumber] = useState("");
    const [visitorRoute, setVisitorRoute] = useState("");
    const [customizeRoute, setCustomizeRoute] = useState("")
    const [userLang, setUserLang] = useState('');

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handleAgree = useCallback(() => {
        setOpen(false);
        setAgreement(true);
    }, []);

    const handleMarketingClose = useCallback(() => {
        setMarketingOpen(false);
    },[])

    const handlerMarketingAgree = useCallback(() => {
        setMarketingOpen(false);
        setMarketingAgreement(true);
    }, []);

    const resetForm = useCallback(() => {
        setCompany("");
        setFullname("");
        setDepartment("");
        setPosition("");
        setEmail("");
        setJob("");
        setCustomizeJob("");
        setNumber("");
        setVisitorRoute("");
        setCustomizeRoute("");
        setUserLang("");
        setAgreement(false);
        setMarketingAgreement(false);
    }, []);

    const [checkMsg, setCheckMsg] = useState({reg:false, msg:''})
    const [numMsg, setNumMsg] = useState({reg:false, msg:''});

    const numRegExp =  /^\d{2,3}-\d{3,4}-\d{3,4}$/;
    // email 체크
    const regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const checkEmail = (info:string) => {
        const isValidEmail = regExp.test(info);

        if (isValidEmail) {
            setCheckMsg({reg:false, msg:t('contact.email_confirm')});
        } else {
            setCheckMsg({reg:true, msg:t('contact.email_notPass')});
        }
    }

    const checkNumber = (info:string) => {
        const firstTwoDigits = info.toString().substring(0,2)
        let newNum;

        if(firstTwoDigits.startsWith("02")) {
            newNum = info.replace(/-/g, "").replace(/(\d{2})(\d{3,4})(\d{4})/, "$1-$2-$3");
        }else {
            newNum = info.replace(/-/g, "").replace(/(\d{3})(\d{3,4})(\d{4})/, "$1-$2-$3");
        }
        const inVailNum = numRegExp.test(newNum);
        setNumber(newNum)

        if (inVailNum) {
            setNumMsg({reg:false, msg:t('contact.phone_confirm')});
        } else {
            setNumMsg({reg:true, msg:t('contact.phone_notPass')});
        }
    }

    const createVoC = useCallback((e) => {
        e.preventDefault();
        const emailIsValid = regExp.test(email);
        const numberIsValid = number ? numRegExp.test(number) : true;

        if (!emailIsValid) {
            setCheckMsg(t('contact.email_notPass'));
            return;
        } else {
            setCheckMsg(t('contact.email_confirm'));
        }

        if (!numberIsValid) {
            setNumMsg(t('contact.phone_notPass'));
        } else {
            setNumMsg(t('contact.phone_confirm'));
        }

        if(mode.mode === 'mobile'){
            mode.close();
        }

        mutate({
            company,
            fullname,
            department,
            position,
            email,
            job,
            customizeJob,
            number,
            visitorRoute,
            customizeRoute,
            userLang,
        }, {
            onSuccess: () => {
                resetForm();
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        company,
        fullname,
        department,
        position,
        email,
        job,
        customizeJob,
        number,
        visitorRoute,
        customizeRoute,
        userLang,
    ]);

    useEffect(() => {
        const processLang = i18n.language === 'ko' ? i18n.language.toUpperCase() : i18n.language === 'en-US' ? i18n.language.substring(0,2).toUpperCase() : 'KO';
            setCompany( "");
            setFullname( "");
            setDepartment("");
            setPosition( "");
            setEmail( "");
            setJob("");
            setCustomizeJob("");
            setNumber("");
            setVisitorRoute("");
            setCustomizeRoute("");
            setUserLang(processLang);
            setAgreement(false);
            setMarketingAgreement(false);
        }, [i18n.language]);

    const openPrivacyDialog = React.useCallback((e: any) => {
        e.preventDefault();
        setOpen(true);
    }, []);

    const openMarketingDialog = React.useCallback((e: any) => {
        e.preventDefault();
        setMarketingOpen(true);
    }, []);

    const jobList = [t('contact.job_student'),
    t('contact.job_salaryMan'),
    t('contact.job_corporate'),
    t('contact.job_sole'),
    t('contact.job_ect')]

    const visitRoute =  [
        t('contact.trade_show'),
        t('contact.friends'),
        t('contact.press'),
        t('contact.job_ect'),
    ]

    const txtJobHandler = (txt:any) => {
        setCustomizeJob(txt);
    }

    const [isActiveFields, setActiveFields] = useState<ActiveFields>({
        name: { value: '', isActive: false },
        job: {value: '', isActive: false },
        customizeJob:{value: '', isActive: false },
        company: {value: '', isActive: false },
        email: { value: '', isActive: false },
        department: {value: '', isActive: false },
        position: {value: '', isActive: false },
        number:{value: '', isActive: false },
        route: {value: '', isActive: false },
        customizeRoute: {value: '', isActive: false },
    });


    const handleFieldFocus = (field:any) => {
        setActiveFields((prevFields) => ({ ...prevFields, [field]: { ...prevFields[field], isActive: true } }));
    };

    const handleFieldBlur = (field:any) => {
        setActiveFields((prevFields) => ({ ...prevFields, [field]: { ...prevFields[field], isActive: false } }));
    };


    return (
        <>
            <Right loading={isLoading} mode={mode.mode}>
                {
                    isLoading ? <div className={'loadingSpinner'}><LoadingSpinner /></div> : null
                }
                <form onSubmit={createVoC}>
                    <Form>
                        <Row>
                            <MobileTxt value={mode.mode}>
                                <Typography className='formtitle' variant={"h6"} style={{margin:0}}>{t("contact.form_title")}</Typography>
                            </MobileTxt>
                        </Row>
                        <Row>
                            <div className={'requireTxt'}>{t('contact.require')}</div>
                        </Row>

                        <Row>
                            {/*이름 */}
                            <TextField
                                required
                                fullWidth
                                variant="outlined"
                                label={isActiveFields.name.isActive ? t('contact.fullname_ex') : t('contact.userinfoForm_fullname')}
                                value={fullname}
                                onChange={(e) => setFullname(e.target.value)}
                                size="small"
                                onFocus={() => handleFieldFocus('name')}
                                onBlur={() => handleFieldBlur('name')}
                            />
                        </Row>
                        <TwinRow value={job}>
                            {/* 직업 */}
                            <div className={'mixRow'}>
                                <FormControl fullWidth className="leftRow">
                                    {job === '' && <InputLabel  id="demo-simple-select-label">{ mode.mode === 'mobile' ? t('contact.mojob')  : t('contact.job') }*</InputLabel> }
                                    <Select
                                        required
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={job}
                                        onFocus={() => handleFieldFocus('job')}
                                        onBlur={() => handleFieldBlur('job')}
                                        onChange={(e) => setJob(e.target.value)}
                                        size="small"
                                    >
                                        {
                                            jobList.map((i) => {return (
                                                <MenuItem key={i} value={i}>{i}</MenuItem>
                                            )})}
                                    </Select>
                                </FormControl>

                                {/*직업 기타 선택 창 */}
                                {job === t('contact.job_ect') ?
                                    <TextField
                                        required={job == t('contact.job_ect')}
                                        fullWidth
                                        variant="outlined"
                                        label={isActiveFields.customizeJob.isActive ? t('contact.customizeJob') : t('contact.userinfoForm_customizeJob')}
                                        value={customizeJob}
                                        onChange={(e) => txtJobHandler(e.target.value)}
                                        className={'ectTxtfield'}
                                        size='small'
                                        onFocus={() => handleFieldFocus('customizeJob')}
                                        onBlur={() => handleFieldBlur('customizeJob')}
                                    />
                                    : null}

                                <TextField
                                    required={job != t('contact.job_ect')}
                                    fullWidth
                                    variant="outlined"
                                    label={isActiveFields.company.isActive ? t('contact.company_ex') : t('contact.userinfoForm_company')}
                                    onFocus={() => handleFieldFocus('company')}
                                    onBlur={() => handleFieldBlur('company')}
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                    size="small"
                                    className={"rightRow"}
                                />
                            </div>
                        </TwinRow>
                        <Row>
                            <TextField
                                required
                                type="email"
                                fullWidth
                                variant="outlined"
                                label={isActiveFields.email.isActive ? t('contact.email_ex') : t('contact.userinfoForm_email')}
                                helperText={checkMsg.msg}
                                error={checkMsg.reg}
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    checkEmail(e.target.value);
                                }}
                                onFocus={() => handleFieldFocus('email')}
                                onBlur={() => handleFieldBlur('email')}
                                size="small"
                            />
                        </Row>


                        {mode.mode === 'mobile' ?
                            <>
                                <Row>
                                    <TextField
                                        fullWidth
                                        disabled={job !== t('contact.job_salaryMan') && job !== ''}
                                        style={{ borderRadius:"5px", background: job !== t('contact.job_salaryMan') && job !== '' ? "#d7d7d7" : "none" }}
                                        variant="outlined"
                                        label={isActiveFields.department.isActive ? t('contact.department_ex') :t("contact.userinfoForm_department")}
                                        onFocus={() => handleFieldFocus('department')}
                                        onBlur={() => handleFieldBlur('department')}
                                        value={department}
                                        onChange={(e) => setDepartment(e.target.value)}
                                        size="small"
                                    />
                                </Row>
                                <Row>
                                    <TextField
                                        fullWidth
                                        disabled={job !== t('contact.job_salaryMan') && job !== ''}
                                        style={{ borderRadius:"5px", background: job !== t('contact.job_salaryMan') && job !== '' ? "#d7d7d7" : "none" }}
                                        variant="outlined"
                                        label={isActiveFields.position.isActive ? t('contact.position_ex') :t("contact.userinfoForm_position")}
                                        onFocus={() => handleFieldFocus('position')}
                                        onBlur={() => handleFieldBlur('position')}
                                        value={position}
                                        onChange={(e) => setPosition(e.target.value)}
                                        size="small"
                                    />
                                </Row>
                            </>
                            :
                            <HalfRow>
                                <TextField
                                    fullWidth
                                    disabled={job !== t('contact.job_salaryMan') && job !== ''}
                                    style={{ borderRadius:"5px", background: job !== t('contact.job_salaryMan') && job !== '' ? "#d7d7d7" : "none" }}
                                    variant="outlined"
                                    value={department}
                                    onChange={(e) => setDepartment(e.target.value)}
                                    size="small"
                                    label={isActiveFields.department.isActive ? t('contact.department_ex') :t("contact.userinfoForm_department")}
                                    onFocus={() => handleFieldFocus('department')}
                                    onBlur={() => handleFieldBlur('department')}
                                />
                                <TextField
                                    fullWidth
                                    disabled={job !== t('contact.job_salaryMan') && job !== ''}
                                    style={{ borderRadius:"5px", background: job !== t('contact.job_salaryMan') && job !== '' ? "#d7d7d7" : "none" }}
                                    variant="outlined"
                                    value={position}
                                    onChange={(e) => setPosition(e.target.value)}
                                    size="small"
                                    label={isActiveFields.position.isActive ? t('contact.position_ex') :t("contact.userinfoForm_position")}
                                    onFocus={() => handleFieldFocus('position')}
                                    onBlur={() => handleFieldBlur('position')}
                                />
                            </HalfRow>
                        }
                        <Row>
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                value={number}
                                size="small"
                                label={isActiveFields.number.isActive ? t('contact.number_ex') : t('contact.userinfoForm_number')}
                                helperText={numMsg.msg}
                                error={numMsg.reg}
                                onChange={(e) => {
                                    setNumber(e.target.value);
                                    checkNumber(e.target.value);
                                }}
                                onFocus={() => handleFieldFocus('number')}
                                onBlur={() => handleFieldBlur('number')}
                            />
                        </Row>

                        <VisitorRow value={visitorRoute}>
                            <div className={'mixRow'}>
                                <FormControl fullWidth className="leftRow">
                                    {visitorRoute === '' && <InputLabel id="demo-simple-select-label">{t('contact.visitor_route')}</InputLabel> }
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={visitorRoute}
                                        onChange={(e) => setVisitorRoute(e.target.value)}
                                        size="small"
                                    >
                                        {
                                            visitRoute.map((i) => {return (
                                                <MenuItem value={i}>{i}</MenuItem>
                                            )

                                            })
                                        }
                                    </Select>
                                </FormControl>

                                {/* 방문경로 기타 선택 */}
                                {visitorRoute === t('contact.job_ect') ?
                                    <TextField
                                        required={visitorRoute == t('contact.job_ect')}
                                        fullWidth
                                        variant="outlined"
                                        value={customizeRoute}
                                        onChange={(e) => setCustomizeRoute(e.target.value)}
                                        className={'ectTxtfield'}
                                        size='small'
                                        label={isActiveFields.customizeRoute.isActive ? t('contact.customizeRoute') : t('contact.userinfoForm_customizeJob')}
                                        onFocus={() => handleFieldFocus('customizeRoute')}
                                        onBlur={() => handleFieldBlur('customizeRoute')}
                                    />
                                    : null}
                            </div>
                        </VisitorRow>

                        <Row>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <FormControlLabel
                                    control={(
                                        <Checkbox
                                            checked={agreement}
                                            onChange={(e) => {
                                                setAgreement(e.target.checked);
                                            }}
                                        />
                                    )}
                                    label={(
                                    <MobileTxt value={mode.mode}>
                                    <span className={'agreeTxt'}>
                                    {t("contact.account_agree_msg1")} <u onClick={openPrivacyDialog}>{t("contact.account_agree_popup")}</u>{t("contact.account_agree_msg2")}
                                 </span>
                                    </MobileTxt>
                                    )}
                                />
                                <FormControlLabel
                                    control={(
                                        <Checkbox
                                            checked={marketingAgreement}
                                            onChange={(e) => {
                                                setMarketingAgreement(e.target.checked);
                                            }}
                                        />
                                    )}
                                    label={(
                                        <MobileTxt value={mode.mode}>
                                    <span  className={'agreeTxt'}>
                                     {t("contact.account_agree_msg1")}
                                        <u onClick={openMarketingDialog}>{t("contact.account_marketing_popup")}</u>
                                        {t("contact.account_agree_msg2")}
                                 </span>
                                        </MobileTxt>
                                    )}
                                />
                            </div>
                        </Row>
                        <ButtonRow>
                            <Button
                                disabled={!agreement || !marketingAgreement}
                                size="large"
                                variant="contained"
                                disableElevation
                                type={'submit'}
                            >
                                {t("contact.submit")}
                            </Button>
                        </ButtonRow>
                    </Form>
                </form>
            </Right>
            <PPDialog
              open={open}
              onClose={handleClose}
              onAgree={handleAgree}
              maxWidth="sm"
              fullWidth
            />
            <MarketingDialog
                open={marketingOpen}
                onClose={handleMarketingClose}
                onAgree={handlerMarketingAgree}
                maxWidth="sm"
                fullWidth/>
        </>
    );
};


export default UserInfoForm;

const Right = styled.div<RightProps>`

  display: flex;
  flex-direction: column;
  width: ${(props) => (props.mode === 'pc' ? "40%" : "100%")};
  margin: auto;
  opacity: 0;
  border-radius: ${(props) => (props.loading ? "10px" : "none")};
  filter: ${(props) => (props.loading ? "blur(0.6px)" : "none")};
  
  .loadingSpinner{
    z-index: 1;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
  }
  
  @keyframes contact-right {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0%);
    }
  }
  animation: contact-right .7s;
  animation-fill-mode: forwards;


`;


const Form = styled.div`
  margin: 13px;
`;

const Row = styled.div`
  margin: 8px;

  .formtitle {
    color: #595959;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .mixRow {
    display: flex;

    .leftRow {
      display: flex;
      width: 40%;
      margin-right: 10px;
    }

    .rightRow {
      width: 60%;
      font-size: 10px;
    }
  }

  .requireTxt {
    display: flex;
    justify-content: flex-end;
    color: #676767;

  }
`;

const TwinRow = styled.div<RowProps>`
  margin: 8px;
  
  #demo-simple-select-label {
    transform: translate(10px,12px) scale(1);
    overflow: hidden;
  }
  .formtitle {
    color:#595959;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .mixRow{
    display: flex;

    .ectTxtfield {
      width: 30%;
      margin-right: 10px;
    }
    .leftRow {
      display: flex;
      width: ${(props) => props.value === '기타' || props.value === 'ect' ? '10%' : '40%'};
      margin-right: 10px;
    }
    .rightRow {
      width:60%;
      font-size: 10px;
    }
    
  }
`;

const VisitorRow = styled.div<RowProps>`
  margin: 8px;
  
  #demo-simple-select-label {
    transform: translate(10px,12px) scale(1);
    
  }
  .formtitle {
    color:#595959;
    font-weight: 600;
    margin-bottom: 15px;
  }
  
  .mixRow{
    display: flex;

    .ectTxtfield {
      width: 50%;
      margin-left: 10px;
    }
    .leftRow {
      display: flex;
      width: ${(props) => props.value === '기타' || props.value === 'ect' ? '50%' : '100%'};
    }
  }
`;

const MobileTxt = styled.div<RowProps>`
    .formtitle{
      font-size: ${(props) => props.value === 'mobile' ? "18px" : ""};
    }
    font-size: ${(props) => props.value === 'mobile' ? "13px" : ""};
`;

export const ButtonRow = styled.div`
  margin: 10px 0 10px 0;
  display: flex;
  justify-content: center;
  & > .MuiButton-root {
    background-color: ${props => props.theme.palette.secondary.main};
    color: #fff;
    width: 97%;
    font-size: 1rem;
    &:disabled {
      background-color: rgba(0,0,0,0.12);
      color: rgba(0,0,0,0.26);
    }
  }
`;

const HalfRow = styled.div`
  margin: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > * {
    flex: 0 0 calc(50% - 4px);
  }
`;
