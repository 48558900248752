import React from 'react';
import {useTranslation} from "react-i18next";
import mail from "../assets/mail.mp4"
import Header from "../components/Header";
import {Button, Container} from "@material-ui/core";
import styled from "styled-components";
import {useHistory} from "react-router";

const SendInfo = () => {
    const history = useHistory();
    const {t} = useTranslation();
    const clickBtn = () => {
        history.push('/')
    }
    return (

        <>
            <Header />
            <SendInfoStyled>
                <Container maxWidth="md">
                    <TypographyTitle>{t('send.thankU')}</TypographyTitle>
                    <TypographyTitle>{t('send.checkmail')}</TypographyTitle>
                    <video autoPlay muted poster="aaa" preload="auto" width={"100px"}>
                        <source src={mail} type="video/mp4" />
                    </video>
                </Container>
                <ButtonStyled>
                    <Button
                        className={'button'}
                        variant="contained"
                        disableElevation
                        onClick={clickBtn}
                    >
                        {t("send.renderHome")}
                    </Button>
                </ButtonStyled>

            </SendInfoStyled>

        </>

    );
};

export default SendInfo;


const SendInfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  text-align: center;

  video{
    margin: 30px 0;
  }
  @keyframes contact-left {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 0.75;
      transform: translateY(0%);
    }
  }
  animation: contact-left .7s;
  animation-fill-mode: forwards;
  
`;

const ButtonStyled = styled.div`
.button{
  width: 300px;
  font-weight: 600;
}
`

const TypographyTitle = styled.h2`
    margin-top: 10px;
`;

