import React from 'react';
import camera from "../mobilesvg/image-svgrepo-com.svg";
import search from "../mobilesvg/search-svgrepo-com.svg";
import monitor from "../mobilesvg/monitor-tv-svgrepo-com.svg";
import {MobileTitleBar, Wrapper, FunctionBoxStyled} from "../index"
import styled from "styled-components";
import {useTranslation} from "react-i18next";


const MobileAbout = () => {
const {t, i18n} = useTranslation();
    return (
        <div>
            <MobileTitleBar>
                <div className={'grayBox'} id={'grayfirst'}>
                    <div className={'textBox'} >
                        {
                            i18n.language === 'en-US' ?
                                <>
                                    About <span className={'vodaText'}>V<span className={'blue'}>O</span>DA</span>
                                </>
                                :
                                <>
                                    <span className={'vodaText'}>V<span className={'blue'}>O</span>DA</span>
                                </>
                        }
                    </div>
                    <div className={'blackMiniBox'}></div>
                </div>
            </MobileTitleBar>

            <Wrapper>
                <AboutMainTextBox>
                    <div>
                        <span>V<span style={{color: "#23b99e"}}>O</span>DA</span> {t('mo.provide') || ''}
                    </div>
                    <div className={'greenTextBox'} style={{color: "#23b99e"}}>
                        ALL-IN-ONE
                    </div>

                    <div>
                        {t('mo.platform') || ''}
                    </div>
                </AboutMainTextBox>

                <FunctionBoxStyled>
                    <div className={'funcBox'}>
                        <div className={'imageBox'}>
                            <img src={camera} alt={'camera'}/>
                        </div>
                        <div className={'title'}>{t('mo.manage') || ''}</div>
                        <div className={'line'}></div>
                        {
                            i18n.language === 'en-US' ?
                                <>
                                    <div className={'contents'}>{t('mo.Imaginal') || ''}</div>
                                </>
                                :
                                <>
                                    <div className={'contents'}>{t('mo.Imaginal') || ''}
                                    <br />
                                        {t('mo.Imaginal_1') || ''}
                                    </div>
                                </>
                        }

                    </div>

                    <div className={'funcBox'}>
                        <div className={'imageBox'}>
                            <img src={search} alt={'search'}/>
                        </div>
                        <div className={'title'}>{t('mo.inspect') || ''}</div>
                        <div className={'line'}></div>

                        {
                            i18n.language === 'en-US' ?
                                <>
                                    <div className={'contents'}>{t('mo.high') || ''}</div>
                                </>
                                :
                                <>
                                    <div className={'contents'}>{t('mo.high') || ''}
                                        <br />
                                        {t('mo.high_1') || ''}
                                    </div>
                                </>
                        }



                    </div>

                    <div className={'funcBox'}>
                        <div className={'imageBox'}>
                            <img src={monitor} alt={'monitor'}/>
                        </div>
                        <div className={'title'}>{t('mo.monitor') || ''}</div>
                        <div className={'line'}></div>
                        {
                            i18n.language === 'en-US' ?
                                <>
                                    <div className={'contents'}>{t('mo.live') || ''}</div>
                                </>
                                :
                                <>
                                    <div className={'contents'}>{t('mo.live') || ''}
                                        <br />
                                        {t('mo.live_1') || ''}
                                    </div>
                                </>
                        }

                    </div>
                </FunctionBoxStyled>
            </Wrapper>
        </div>
    );
};


const AboutMainTextBox = styled.p`
  text-align: center;
  font-size: 1.2rem;
  margin-top: 1rem;
  line-height: 2.4rem;
  color: #4a4a4a;
  
  div:nth-child(1) {
    span{
      font-size: 1.6rem;
      font-weight: 600;
    }
  }

  div:nth-child(2) {
    width: 10rem;
    margin: auto;
    font-weight: 600;
    background: repeating-linear-gradient(45deg, #ffffff, #edf5e8 10px, #edf5e8 0, #edf5e8 20px);
  }
`

export default MobileAbout;