import React from 'react';
// @ts-ignore
import ReactFullpage from "@fullpage/react-fullpage";
import styled from "styled-components";
import Header from "../../components/Header";
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Page4 from "./Page4";
import Page5 from "./Page5";
import Page7 from "./Page7";
import { Button, Hidden, Container } from "@material-ui/core";
import Footer from "./Footer";
import PageVideo from "./PageVideo";
const Root = styled.div`
  position: relative;
`;

const Navigation = styled.div`
  z-index: 6;
  position: fixed;
  left: 0;
  top: 200px;
  opacity: 1;
  transition: opacity 0.3s;
  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
`;

export const NavItem = styled.div`
  display: flex;
  align-items: center;
  & > span {
    font-size: 1.125rem;
    font-weight: 400;
    cursor: pointer;
    transition: transform 0.5s;
  }
  & > div {
    opacity: 0;
    transition: opacity 0.5s;
  }
  &.active > span {
    font-weight: 600;
    transform: translateX(10%);
  }
  &.active > div {
    opacity: 1;
  }
  margin: 12px 0;
`;

export const NavItemBar = styled.div`
  width: 64px;
  height: 4px;
  background-color: #2E323C;
  margin-right: 16px;
`;

const CustomButton = styled(Button)`
  position: absolute;
  right:1.5rem;
  top:85vh;
  width: 2rem;
  height: 4rem;
  border-radius: 50%;
  font-size: 1.1rem;
  font-weight: 600;
  background-color: rgba(0,0,0,0.3);
  color: #333;
  border: 1px solid #333;
  z-index: 1;
`;

export const CustomBlackButton = styled(CustomButton)`

  @keyframes fade-slide-in-bottom {
    from {
      transform: translateY(700%);
    }
    to {
      transform: translateY(0);
    }
  }
  @keyframes fade-slide-out-bottom {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(700%);
    }
  }
  
  transition: opacity .2s;
  
  &:not(.hidden) {
    opacity: 0.8;
    animation: fade-slide-in-bottom 1.2s;
  }
  
  &.hidden {
    opacity: 0;
    animation: fade-slide-out-bottom 1.2s;
  }
`;


export interface PageProps {
  active?: boolean;
}

const navs = ['Deep-learning', 'Monitoring', 'Maintenance'];

export default function About() {
  const [index, setIndex] = React.useState(0);
  const [nextIndex, setNextIndex] = React.useState(0);
  const inAbout = index < 3 || index > 6;
  const inService = index >= 3 && index <= 6;

  const leaveAbout = nextIndex >= 3 && nextIndex <= 6;
  const leaveService = nextIndex < 3 || nextIndex > 6;
  const willAbout = (inAbout && !leaveAbout) || (!inAbout && !leaveAbout);
  const willService = (inService && !leaveService) || (!inService && !leaveService);

  const apiRef = React.useRef<any>();
    const showBackToTheTopButton = (index !== 7 && nextIndex === 7) || (index === 7 && nextIndex === 7);
  React.useEffect(() => {
    window.setTimeout(() => {
      if (apiRef.current && window.location.hash === '#service') {
        apiRef.current?.moveTo(4, 0);
      }
    }, 200);
  }, []);

  return (
    <Root>
      <Header
        noBg
        inAbout={willAbout}
        inService={willService}
        onAboutActive={() => {
          apiRef.current?.moveTo(1, 0);
        }}
        onServiceActive={() => {
          apiRef.current?.moveTo(4, 0);
        }}
      />

      <Hidden lgDown>
        <Navigation className={willService ? '' : 'hidden'}>
          {navs.map((nav, i) => (
            <NavItem
              key={nav} className={nextIndex - 3 === i ? 'active' : ''}
              onClick={() => {
                apiRef.current?.moveTo(i + 4, 0);
              }}
            >
              <NavItemBar />
              <span>{nav}</span>
            </NavItem>
          ))}
        </Navigation>
      </Hidden>

      <CustomBlackButton
        className={showBackToTheTopButton ? '' : 'hidden'}
        onClick={() => {
          apiRef.current?.moveTo(1, 0);
        }}
      >
       TOP
      </CustomBlackButton>


    <ReactFullpage
        licenseKey={"500EA6AC-3E384E75-BC55DF41-57A10362"}
        navigation={true}
        scrollingSpeed={1000}
        onLeave={(origin: any, destination: any) => {
            setNextIndex(destination.index);
        }}
        afterLoad={(origin: any, destination: any) => {
            setIndex(destination.index);
        }}
        render={({ fullpageApi }: any) => {
            apiRef.current = fullpageApi;
            return (
                <ReactFullpage.Wrapper>
                        <div className="section">
                            <Page1 active={index === 0 || nextIndex === 0} />
                        </div>

                    {/*비디오 첨부*/}
                    <div className="section">
                        <PageVideo active={index === 1 || nextIndex === 1} />
                    </div>
                        <div className="section">
                            <Page2 active={index === 2 || nextIndex === 2} />
                        </div>
                        <div className="section">
                            <Page3 active={index === 3 || nextIndex === 3} />
                        </div>
                        <div className="section">
                            <Page4 active={index === 4 || nextIndex === 4} />
                        </div>
                        <div className="section">
                            <Page5 active={index === 5 || nextIndex === 5} />
                        </div>
                        <div className="section">
                            <Page7 active={index === 6 || nextIndex === 6} />
                        </div>
                        <div className="section">
                          <Footer active={index === 7 || nextIndex === 7} />
                        </div>
                </ReactFullpage.Wrapper>
            );
        }}
    />
    </Root>
  );
}
