import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';


export const lightTheme = createMuiTheme({
  typography: {
    fontFamily: '"Poppins", "NanumBarunGothic", "Roboto", sans-serif',
  },
  palette: {
    type: 'light',
    primary: {
      dark: '#214A34',
      main: '#2e7d32',
      light: '#57975b',
    },
    secondary: {
      light: '#DAF3EF',
      main: '#23B99E',
      dark: '#155fa0',
    },
  },
});

export const darkTheme = createMuiTheme({
  typography: {
    fontFamily: '"Poppins", "NanumBarunGothic", "Roboto", sans-serif',
  },
  palette: {
    type: 'dark',
    primary: {
      dark: '#2e7031',
      main: '#43a047',
      light: '#68b36b',
    },
    secondary: {
      light: '#DAF3EF',
      main: '#23B99E',
      dark: '#155fa0',
    },
  },
});