
const ko = {
  global: {
    unexpectedError: '처리되지 않은 에러가 발생하였습니다. 관리자에게 문의하세요.',
    newVersion: '새 버전이 출시되었습니다. 새로고침하여 사용해주세요!',
    close: '닫기',
    voda_privacy:'개인정보 처리방침',
    career_tab:'채용정보 페이지로 이동합니다.',
  },
  ppdial:{
    privacy:"홈페이지 내 개인정보 수집 및 이용 동의서",
    exit:"닫기",
    agree:"동의",
    marketing:"마케팅 정보 수신 동의서"
  },
  video:{
    voda:"는 어떤 회사인가요?"
  },
  send:{
    thankU:"귀하의 소중한 정보 감사합니다.",
    checkmail:"메일함을 확인해주세요.",
    renderHome: '홈 화면 이동'
  },
  login: {
    email: '이메일 주소',
    password: '비밀번호',
    signIn: '인증',
    forgotPassword: '비밀번호를 잊으셨나요?',
    recommendSignUp: '계정이 없으신가요?',
    error: '로그인에 실패하였습니다. 이메일 주소와 패스워드를 확인해주세요.',
  },
  demo: {
    deleteImageSuccess: '이미지가 제거되었습니다.',
    updateLabelTemplateSuccess: '라벨이 갱신되었습니다.',
    createLabelTemplateSuccess: '라벨이 생성되었습니다.',
    deleteLabelTemplateSuccess: '라벨이 삭제되었습니다.',
    createLabelSuccess: '라벨 영역이 등록되었습니다.',
    deleteLabelSuccess: '라벨 영역이 제거되었습니다.',
    updateImageSuccess: '이미지 정보가 저장되었습니다.',
    gridSelectionRequired: '격자 영역이 최소 1개 이상 선택되어야 합니다.',
    name: '이름',
    color: '색상',
  },
  about: {
    hyundai: '현대자동차그룹 출신의 생산이해도가 높은',
    pro: '전문 인력',
    hyundai_end: '으로 구성',
    real: '실제',
    hd: '현대자동차그룹',
    records:'(현대자동차, 현대트랜시스) 생산공정 AI검사 적용 및 검증 실적 보유',
    field: '생산 현장에 필요한',
    only: '만의 차별화된 서비스 제공',
    diff: '의 차별화 서비스',
    aicam:'AI 카메라',
    analysis: '판독 프로그램',
    preDiagnosis:'사전 진단 서비스',
    dataAnalysis:'데이터 분석 서비스',
    dataManagement:'데이터 관리 서비스',
    dataMonitor:'데이터 모니터링 서비스',
  },
  service_deep: {
    nav:'서비스 네비게이션 기능',
    nav_desc:'GPU 활용 능력을 극대화한 Docker Container 기반의',
    nav_desc_2:'서버 중앙형 머신비전 공정 딥러닝 검사 기술을 제공합니다.',
    gpu: 'GPU Scale in/out 과 분산 병렬처리를 통해 다중 공정의 품질 분석을',
    connect: '동시에 수행합니다. (하나의 GPU 서버로 30 개 이상의 공정 연동 가능)',
    few:'적은 양의 이미지(Few shonts)으로도 고성능 AI모델 생성이 가능합니다.',
    realtime:'현장 데이터 실시간 수집이 가능합니다.',
    data:'수집된 데이터는 실시간 양 불 검증 후 향후 학습을 위한 자동 데이터',
    classification:'분류 기능이 탑재되어 있습니다.',
    update:'VODA 의 서비스와 딥러닝 모델은 원격으로 업데이트가 가능하여',
    non_contact:'비대면으로 높은 성능을 유지하는 것이 가능합니다',
  },
  monitoring_service: {
    monitor: '공정 관제 서비스',
    collect:'생산 현장의 모든 공정 데이터를 한 곳에 수집하고',
    dashboard:'한 눈에 들어오는 대시보드 형태로 제공합니다.',
    solution:'VODA connector을 통해 현장에 설치된 다른 솔루션과 자연스럽게',
    connect:'연동되어 공정 현황을 파악하는데 최적화된 서비스를 제공합니다',
    monitoring:'공정 모니터링',
    status:'연결되어있는 모든 공정의 상태와',
    rate:'정확도를 한 눈에 볼 수 있는 서비스입니다.',
    factor:'공정의 모니터링 요소',
    check:' 해당 공정에 확인해야할 사항을',
    visualize:'사용자에 편의에 맞게 시각화할 수 있습니다.',
  },
  maintenance: {
    help:'다양한 이상 상황을 식별하고 원인을 분석하여',
    trouble:' 문제 발생시 도움을 줄 수 있습니다.',
    statistical:'통계 분석',
    anomalous:'이상값 분석',
    real_time:'실시간 감지',
  },
  company:'더 알아보기',

  contact: {
    contact:'문의하기',
    interest:'에 관심을 가져주셔서 감사합니다.',
    function:'영업, 기술지원 및 서비스',
    leave:'에 관한 문의 남겨주시면',
    call:'만족하실 수 있는 답변드리도록 최선을 다하겠습니다.',
    contents_1:'',
    send_brochure:"회사 설명자료 받아보기",
    fullname_ex:"예시) 김보다",
    department_ex:"예시) 제조DT2팀",
    position_ex:"예시) 팀장",
    job_ex:"직업을 선택해주세요",
    email_ex:"예시) contact@vodavision.ai",
    number_ex:"연락 가능한 번호를 입력해주세요(숫자만)",
    company_ex:"예시) 주식회사 보다에이아이",
    customizeJob:'정보를 입력해주세요',
    email_confirm:"사용가능한 이메일 형식입니다.",
    email_notPass:"이메일 형식이 옳바르지 않습니다.",
    phone_confirm:"사용가능한 번호 형식입니다.",
    phone_notPass:"번호 형식이 옳바르지 않습니다.",
    userinfoForm_fullname:"이름",
    userinfoForm_company:"소속 / 회사",
    userinfoForm_department:"부서",
    userinfoForm_position:"직위/직책",
    userinfoForm_email:"이메일",
    userinfoForm_number:"휴대전화 / 사무실 번호",
    userinfoForm_customizeJob:"기타 정보",
    customizeRoute:"기타 경로를 입력해주세요",
    account_agree_msg1:"",
    account_agree_popup:"개인정보 수집 및 이용",
    account_marketing_popup:"마케팅 정보 수신",
    account_agree_msg2:"에 동의합니다.",
    submit:"제출하기",
    form_title:"소중한 정보를 입력해주세요",
    require:"* 는 필수 입력해주세요",
    job:"직업을 선택해주세요",
    job_student:"학생",
    job_salaryMan:"직장인",
    job_corporate:"법인 사업자",
    job_sole:"개인 사업자",
    job_ect:"기타",
    visitor_route:"접속 경로를 선택해주세요",
    trade_show:"전시회/박람회",
    friends:"지인 소개",
    press:"언론",
    confirmMsg:"입력 하신 정보를 확인해주세요.",
    mojob:'직업',

  },
  mo: {
    provide:'는 제조기업에게',
    platform:'AI 머신비전 플랫폼 서비스를 제공합니다.',
    manage:'데이터 관리',
    Imaginal:'제조 이미지',
    Imaginal_1: '데이터 보호',
    inspect:'품질 검사',
    high:'높은 검출력으로',
    high_1:  '제품 검사',
    monitor:'실시간 관제',
    live:'품질 검사 상태 ',
    live_1:'실시간 관리',
    fastening:'부품 체결 검사',
    environment_1:'조명, 위치 등 현장 환경에 영향을 받은',
    environment_2:'잘못된 검사 결과를 개선함',
    duplicate:'부품의 중복 체결 여부 판단율을 높임',
    existing:'[기존 검사 방식]',
    after:'[VODA 적용 후]',
    accuracy:'검사 정확도',
    shape:'형상 검사',
    added:'검사 과정 추가',
    speed:'검사 속도:200 EA/분',
    insAccuracy:'검사 정확도',
    productivity:'생산성 향상',
    cost:'비용 절감',
    video:'소개 영상'

  },
  footer: {
    company_name:'(주)보다에이아이',
    ceo:'대표자',
    address:"주소: 서울특별시 서초구 마방로 8, 6층(양재동, 봄날아침빌딩)",
    registration:"사업자등록번호",
    head_q:'대표번호',
    head_num:"02-1661-4078",
    general:'이메일 문의',
    recruit:'채용 문의',
  }

}

export default ko;
