import React from 'react';
import styled from "styled-components";
import {PageProps} from "./index";
import footerVideo from "../../footerVideo.mp4";
import {useTranslation} from "react-i18next";
import { Link } from "react-router-dom";
import PrivacyBtn from "../../components/PrivacyBtn";

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 75%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding:2rem 5rem;
  
  
`;
const LeftTextBox = styled.div`
    display: flex;
  flex-direction: column;
    font-size: 1.1rem;
    color: #4f4f4f;
  .upperBox{
    display: flex;
  }
  .underBox{
    font-size: 1rem;
    margin-top: 2rem;
    div{
      margin-bottom: 0.6rem;
    }
  }
  hr{
    height: 10rem;
    margin:0 3rem;
  }
  
`;

const RightBox = styled.div`
    img{
      margin-top: 1rem;
      width: 9rem;
    }
`
const Video = styled.video`
    width:25rem;
    
`;

export default function Footer(props: PageProps) {
const {t, i18n} = useTranslation();
const moveToPrivacy = () => {

};
  return (
    <Root>
  <LeftTextBox>
      <div className={'upperBox'}>
          <div>
              <div>{t("footer.company_name")} | {t("footer.ceo")}: 전창연</div>
              <br/>
              <div>{t("footer.address")}</div>
              <br/>
              <div>{t("footer.registration")}: 823-87-02509</div>
          </div>
          <hr/>
          <div>
              <div>{t("footer.head_q")}: {t("footer.head_num")}</div>
              <br/>
              <div>{t("footer.general")}: contact@vodavision.ai</div>
              <br/>
              <div>{t("footer.recruit")}: recruit@vodavision.ai</div>
          </div>
      </div>
      <div className={'underBox'}>
          {
              i18n.language === 'en-US' ?
                  null
                  :   <div>본 사이트의 모든콘텐츠는 저작권법의 보호를 받는 바, 무단전재, 복사, 배포 등을 금합니다.</div>
          }

          <div style={{color:"gray", fontSize:"0.9rem"}}>Copyright © VODA AI Inc. All Rights Reserved.</div>

             <PrivacyBtn name={t('global.voda_privacy')} link={'/privacy'} target={'_blank'} top={'0px'}/>

      </div>
  </LeftTextBox>
      <RightBox>
          <Video autoPlay muted>
              <source src={footerVideo} type="video/mp4" />
          </Video>
      </RightBox>
    </Root>
  );
}

// of Data Analysis
