import React from 'react';
import {Button} from "@material-ui/core";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const BrochureButton = (props: { msg: string, activeBtn:any, mode:string }) => {
    const {msg, activeBtn} = props;
    const {i18n} = useTranslation();
    return (
        <ButtonStyled>
            <Button disabled={i18n.language === 'en-US'} variant="outlined" className={'btnBox'} onClick={activeBtn}>
                {msg}
            </Button>
        </ButtonStyled>
    );
};

export default BrochureButton;

const ButtonStyled = styled.div`
  margin-top: 23px;
  margin-bottom: 15px;
  .btnBox {
    background: rgba(65, 194, 171, 0.3);
    font-size: 1rem;

    :hover {
      border: 0.5px solid #606060;
      scale: 1.02;
      transition: all ease-in-out 0.2s;
    }
  }
`
