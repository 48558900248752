import React, {useEffect, useRef} from 'react';
import styled from "styled-components";

const Chart2 = (props:any) => {


    return (
        <ChartStyled>
            <div className="pie">
                <div className="html">{props.per}% </div>
            </div>
        </ChartStyled>

    );
};

export default Chart2;

const ChartStyled = styled.div`
  
  .pie {
    margin: 5vmin auto;
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
    overflow: hidden;
    background: linear-gradient(1deg, #23b99e 50%, transparent 50%), linear-gradient(175deg,#23b99e 50%, #ececec 50%);
    position: relative;
    transform: rotate(-100deg);
  }

  .html {
   position: absolute;
    top:4rem;
    left: 1.8rem;
    font-size: 1.8rem;
    font-weight: 600;
    transform: rotate(100deg);
    
  }
  
`