
const en = {
  global: {
    unexpectedError: 'Unexpected error is occurred.',
    newVersion: 'New version is released! Please refresh the page.',
    close: 'Close',
    voda_privacy:'privacy',
    career_tab:'Move to Career page',
  },
  ppdial:{
    privacy:"Privacy Policy",
    exit:"EXIT",
    agree:"AGREE",
    marketing:"Receive marketing information"
  },
  video:{
    voda_does:"What does",
    voda_do:"do?",
  },
  send:{
    thankU:"Thanks for your information",
    checkmail:"Please check your inbox!",
    renderHome: 'Go to the main page'
  },
  about: {
    hyundai: 'Started as an',
        pro: 'in-house venture',
    hyundai_end: 'from Hyundai Motor Group.',
    eng_end: 'Composed of professional personnel comprehending manufacture.',
    real:'Having records of platform installation in',
    hd:' Hyundai Motor Group',
    records:'(Hyundai Motor Company, Hyundai Transys).',
    prov: 'Providing',
    only:'a differentiated service',
    req: 'required for the manufacturing field.',
    diff:'Differentiated Service Offered by',
    aicam:'AI Camera',
    analysis: 'Analysis Program',
    preDiagnosis:'Pre-diagnosis Service',
    dataAnalysis:'Data Analysis Service',
    dataManagement:'Data Management Service',
    dataMonitor:'Data Monitoring Service',
  },
  service_deep: {
    nav:'Service Navigation',
    nav_desc:'Experience a deep-learning vision inspection technology.',
    nav_desc_2:'We maximize capability of utilizing GPU.',
    gpu: 'We conduct multiple types of quality inspections simultaneously.',
    connect: ' (30+ Manufacturing Stations Linkable to 1 GPU Server)',
    few:'We create a high-performance AI model even with few images.',
    realtime:'You can collect data in real time.',
    data:'For future training, the platform has a function',
    classification:' of automatic data classification.',
    update:'Maintenance of high functionality is possible as the',
    non_contact:'AI model will be remotely updated via the platform.',
  },
  monitoring_service: {
    collect:'Collect and monitor all the manufacturing data in',
    dashboard:' a single platform,.',
    solution:' Linking with other pre-existing equipment,',
    connect:'we provide a service optimized for figuring out QC statuses',
      monitoring:'Station Monitoring',
      status:'You can monitor station statuses',
      rate:'and pass rate of your products.',
      factor:'Customizable Factors',
      check:'You can additionally visualize',
      visualize:'the data you want.',
  },
  maintenance: {
    help:'We are always in the platform to help with',
    trouble:'troubleshooting.',
    statistical:'Statistical Analysis',
    anomalous:'Anomalous Value Analysis',
    real_time:'Real-time Detection',
  },
  company:'MORE',
  contact: {
    contact:'Contact Us',
    interest: 'Thanks for your visit.',
    function_1:'Please leave us a message ',
    function_2:'if you need further information regarding our ',
    leave_1:'service, sales, technical support etc.',
    call:'We will get back to you shortly.',
    contents_1:'Please contact us if you have any questions.',
    send_brochure: "Preparing a brochure",
    userinfoForm_fullname:"fullname",
    userinfoForm_company:"company",
    userinfoForm_department:"department",
    userinfoForm_position:"job position",
    userinfoForm_email:"email",
    userinfoForm_number:"phone / office number",
    account_agree_msg1:"I agree to",
    account_agree_popup:" the terms of service",
    account_marketing_popup:"receive marketing information",
    account_agree_msg2:"",
    submit:"submit",
    form_title: "Please enter your information",
    job:"Jobs",
    job_student:"Student",
    job_salaryMan:"Office workers",
    job_corporate:"Corporate business",
    job_sole:"Sole proprietorship",
    job_ect:"ect",
    visitor_route:"Visit route",
    trade_show:"Trade show",
    friends:"Acquaintance",
    press:"Press",
    confirmMsg:"Please confirm your input.",
  },
  mo: {
    provide:'provides manufactures with',
    platform:'AI Machine Vision Platform Service',
    manage:'MANAGE',
    Imaginal:'The Imaginal Data of Manufacturing',
    inspect:'INSPECT',
    high:'The Products with High Detectability',
    monitor:'MONITOR',
    live:'The QC Statuses',
    fastening:'Part Fastening Check',
    environment_1:'Improved wrong inspection results',
    environment_2:'affected by change of environment(light, location etc.)',
    duplicate:'Raised accuracy of recognizing duplicate fastening',
    existing:'[Existing Method]',
    after:'[After Applying VODA]',
    accuracy:'Inspection Accuracy',
    shape:'Shape Check',
    added:'Added inspection steps',
    speed:'Inspection Speed: 200EA / Min',
    insAccuracy:'Inspection Accuracy',
    productivity:'PRODUCTIVITY',
    cost:'COST REDUCTION',
    video1:'ntroduction',
    video2:'ideo'
  },
  footer: {
    company_name:'VODA AI Inc.',
    ceo:'CEO',
    address:"Address: 6/F, 8, Mabang-ro, Seocho District, Seoul, South Korea",
    registration:"Registration Number",
    head_q:'Headquarters',
    head_num:"+82 02-1661-4078",
    general:'General Enquiry',
    recruit:'Recruitment Enquiry',
  }
}





export default en;
