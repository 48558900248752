import React from 'react';
import styled from "styled-components";
import { Paper } from "@material-ui/core";
import CameraImage from './img/IMG_Camera.png';
import ProgramImage from './img/IMG_Program.png';
import Plus from './img/Plus.svg';
import SearchImage from './img/IMG_Search.png';
import ReportImage from './img/IMG_Report.png';
import DataImage from './img/IMG_Data.png';
import DeviceImage from './img/IMG_Device.png';
import { PageProps } from "./index";
import {useTranslation} from "react-i18next";



const Root = styled.div`
  width: 100%;
  height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
  margin-top: 3rem;
`;

const UpperContainer = styled.div`
  display: flex;
  width: 75rem;
  height: 14rem;
  margin: 0 auto;
  position: relative;
`

const LeftCard = styled.div`
  width: 45rem;
  background-color: ${props => props.theme.palette.secondary.light};
  height: 15rem;
  position: relative;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.05);
  align-content: center;
  align-items: center;
`;

const Bar = styled.div`
  position: absolute;
  left: -2rem;
  top:-6rem;
  height: .75rem;
  width: 4rem;
  margin-top: 10rem;
  background-color: #2E323C;
`;

const AboutVODA = styled.div`
  padding-top: 3rem;
  margin-left: 7rem;
  font-size: 3rem;
  font-weight: 800;
`;

const RightCard = styled(Paper)`
  position: absolute;
  width: 40rem;
  right: -2rem;
  top:1.1rem;
  padding: 3rem 0 3rem 4rem;
  font-size: .9rem;
  font-weight: 400;
  word-break: keep-all;
  & p {
    margin: 0;
    line-height: 2;
  }
  & span {
    color: ${props => props.theme.palette.secondary.main};
  }
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.05);
`;


const MiddleBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`
const MiddleBox = styled.div`
  height: 328px;
  width: 75rem;
  margin: 0 auto;
  border: 5px solid ${props => props.theme.palette.secondary.light};
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  .middleBoxContents{
    display: flex;
    align-items: center;
  }
  .leftIconBox{
    display: flex;
    align-items: center;
  }
  .rightIconBox{
    div{
      display: flex;
    }
  }
`;

const MiddleBoxDescription = styled.div`
  position: absolute;
  font-size: 1.5rem;
  top: -1rem;
  left: calc(50vw - 160px);
  right: calc(50vw - 160px);
  font-weight: 400;
  background-color:#fff;
  text-align: center;
`;

const ServiceBox = styled.div`
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 0 16px 0 rgba(0,0,0,0.05);
  margin: 10px;
  & > p {
    text-align: center;
    color: ${props => props.theme.palette.secondary.main};
    font-size: 1rem;
  }
`;

const CameraServiceBox = styled(ServiceBox)`
  width: 174px;
  height: 204px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;
  & > img {
    margin-top: 10px;
    padding: 12px;
  }
`;

const ProgramServiceBox = styled(CameraServiceBox)`
  //left: 286px;
`;

const PlusIcon = styled.img`
width: 2rem;
`;

const InspectionServiceBox = styled(ServiceBox)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 278px;
  height: 96px;
  top: 56px;
  left: 576px;
  padding: 0 32px;
`;

const AnalysisServiceBox = styled(InspectionServiceBox)`
  //left: 866px;
`;

const ManagementServiceBox = styled(InspectionServiceBox)`
  //top: 164px;
`;

const MonitoringServiceBox = styled(InspectionServiceBox)`
  //top: 164px;
  //left: 866px;
  //padding: 0 16px 0 32px;
`;

const OnlineUntactManagement = styled.div`
  position: absolute;
  bottom: -2rem;
  width: 646px;
  height: inherit;  
  left: calc(50vw - 323px);
  padding:0.5rem;
  border-radius: 0.6rem;
  & > p {
    text-align: center;
    font-weight: 600;
    font-size: 36px;
    color: #ffffff;
    margin: 0 auto;
  }
  background:#23b99e;
`;


export default function Page2(props: PageProps) {
  const { t, i18n } = useTranslation()

    return (
    <Root>
        <UpperContainer>
                <LeftCard>
                    <Bar />
                    <AboutVODA>About VODA</AboutVODA>
                </LeftCard>

                <RightCard square>
                    {
                        i18n.language === 'en-US' ?
                            <p>
                                {t('about.hyundai')|| ""} <span>{t('about.pro')|| ""}</span> {t('about.hyundai_end')|| ""}
                                <br />
                                {t('about.eng_end')|| ""}
                                <br />
                                {t('about.real')|| ""}<span>{t('about.hd')|| ""} </span>
                                <br/>
                                {t('about.records')|| ""}
                                <br />
                                {t('about.prov')|| ""} <span>{t('about.only')|| ""}</span> {t('about.req')|| ""}
                            </p>
                            :   <p>
                                {t('about.hyundai')|| ""} <span>{t('about.pro')|| ""}</span> {t('about.hyundai_end')|| ""}
                                <br />
                                {t('about.real')|| ""} <span>{t('about.hd')|| ""} </span>
                                {t('about.records')|| ""}
                                <br />
                                {t('about.field')|| ""} <strong>V<span>O</span>DA</strong>{t('about.only')|| ""}
                            </p>
                    }

                </RightCard>

        </UpperContainer>

<MiddleBoxContainer>
    <MiddleBoxDescription>
        {
            i18n.language === 'en-US' ?
                <>
                    {t('about.diff')|| ""} <strong>VODA</strong>
                </>
                :
                <>
                    <strong>VODA</strong>{t('about.diff')|| ""}
                </>
        }
    </MiddleBoxDescription>

    <MiddleBox>

            <div className={"leftIconBox"}>
                <CameraServiceBox>
                    <img src={CameraImage} alt="camera" />
                    <p>{t('about.aicam')|| ""}</p>
                </CameraServiceBox>
                <ProgramServiceBox>
                    <img src={ProgramImage} alt="program" />
                    <p>{t('about.analysis')|| ""}</p>
                </ProgramServiceBox>

            </div>
        <PlusIcon src={Plus} alt="plus icon" />

            <div className={"rightIconBox"}>
                <div>
                    <InspectionServiceBox>
                        <img src={SearchImage} alt="inspection" />
                        <p>{t('about.preDiagnosis')|| ""}</p>
                    </InspectionServiceBox>
                    <AnalysisServiceBox>
                        <img src={ReportImage} alt="analysis" />
                        <p>{t('about.dataAnalysis')|| ""}</p>
                    </AnalysisServiceBox>
                </div>
                <div>
                    <ManagementServiceBox>
                        <img src={DataImage} alt="management" />
                        <p>{t('about.dataManagement')|| ""}</p>
                    </ManagementServiceBox>
                    <MonitoringServiceBox>
                        <img src={DeviceImage} alt="monitoring" />
                        <p>{t('about.dataMonitor')|| ""}</p>
                    </MonitoringServiceBox>
                </div>
        </div>

    </MiddleBox>

    <OnlineUntactManagement>
        <p>Online &amp; Untact Management</p>
    </OnlineUntactManagement>
</MiddleBoxContainer>
    </Root>
  );
}
