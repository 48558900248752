import React from 'react';
import styled from "styled-components";
import { Paper } from "@material-ui/core";
import IScopeImage from "./img/IScope.svg";
import { PageProps } from "./index";
import {useTranslation} from "react-i18next";
import check from "./DraftEng/draftImg/blue-check-mark-icon.svg";
import IScopeDraft from './DraftEng/draftImg/IScope.svg';


const Root = styled.div`
  position: relative;
  width: 100%;
  height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 4.5rem;
`;
const MainContents = styled.div`
  display: flex;
  margin: 0 auto;
  align-items: center;
  position: relative;
  
`
const DownContents = styled.div`
display: flex;
 margin: auto;
`


const Bar = styled.div`
  position: absolute;
  right: -2rem;
  top: 3rem;

  z-index: 1;
  height: .75rem;
  width: 4rem;
  background-color: #2E323C;
`;

const ScopeTextBox = styled.div`
  background-color: ${props => props.theme.palette.secondary.light};
  height: 12rem;
  width: 43rem;
  text-align: right;
  box-sizing: border-box;
  padding: 3rem;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.05);
  position: relative;
`


const ScopeBox = styled.div`
  font-size: 3rem;
  font-weight: 800;
`;

const IScopeDescription = styled.div`
  font-size: 1.25rem;
  font-weight: 400;
`;

const LeftCard = styled(Paper)`
  position: relative;
  z-index: 1;
  top: 0.5rem;
  right: -8rem;
  width: 432px;
  height: 120px;
  padding: 0 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.05);
  & h3 {
    margin-top: 0;
    margin-bottom: 12px;
    font-size: 1.25rem;
    font-weight: 600;
  }
  & p {
    font-weight: 400;
    margin: 0;
    line-height: 1.8;
    font-size: .9rem;
  }
  & span {
    color: ${props => props.theme.palette.secondary.main};
  }
`;

const ImageBox = styled.div`
  border: 5px solid ${props => props.theme.palette.secondary.light};
  height: 28rem;
  width: 42rem;
  display: flex;
  align-items: center;
  justify-content: center;

`;

const DescriptionBox = styled.div`
  margin-left: 3rem;
  & > h3 {
    font-size: 1.25rem;
  }
`;


const MiddleDescriptionBox = styled(DescriptionBox)`
  font-size: 1.1rem;
  margin-top: 2.2rem;
  p{
    margin: 15px 0;
    font-weight: 600;
  }
  img{
    width: 0.9rem;
    margin-right: 0.7rem;
  }
`;


export default function Page5(props: PageProps) {
    const {t, i18n} = useTranslation();

    return (
        <Root>
            <MainContents>
            <LeftCard square>
                <p>
                    {t('maintenance.help') || ""}
                    <br />
                    {t('maintenance.trouble') || ""}
                </p>
            </LeftCard>
                <Bar />
                <ScopeTextBox>
                    <ScopeBox>Maintenance</ScopeBox>
                    {
                        i18n.language === 'en-US' ?
                            null
                            :
                            <IScopeDescription>공정 데이터 분석 서비스</IScopeDescription>

                    }
                </ScopeTextBox>
            </MainContents>



            <DownContents>
                <ImageBox>
                    {
                        i18n.language === 'en-US' ?
                            <img src={IScopeDraft} alt="scope box" />
                            :
                            <img src={IScopeImage} alt="scope box" />

                    }
                </ImageBox>

                <MiddleDescriptionBox>
                    <p><img src={check} alt={'check'} />{t('maintenance.statistical') || ""}</p>
                    <p><img src={check} alt={'check'} />{t('maintenance.anomalous') || ""}</p>
                    <p><img src={check} alt={'check'} />{t('maintenance.real_time') || ""}</p>
                </MiddleDescriptionBox>

            </DownContents>

        </Root>
    );
}
