import React from 'react';
import styled from "styled-components";
import Page7BackgroundImage from './img/Page7Background.png';
import { Button } from "@material-ui/core";
import { PageProps } from "./index";
import { useHistory } from "react-router";
import {useTranslation} from "react-i18next";
import { Link } from 'react-router-dom';

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 8rem;
`;

const Background = styled.img`
  position: relative;
  top: 200px;
  width: 1310px;
  height: 610px;
  left: calc(50vw - 640px);

`;

const Hero = styled.h1`
  margin: 0;
  position: absolute;
  top: 388px;
  width: 650px;
  left: calc(50vw - 325px);
  color: #ffffff;
  font-size: 72px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
`;

export const CustomButton = styled(Button)`
  position: absolute;
  left: calc(50% - 148px);
  top: 40rem;
  width: 296px;
  height: 72px;
  border: 1px solid #ffffff;
  background-color: rgba(255,255,255,0.25);
  border-radius: 0;
  color: #ffffff;
  font-size: 1.125rem;
  font-weight: 600;
`;


export default function Page7(props: PageProps) {
const {t} = useTranslation();
  return (
    <Root>
      <Background src={Page7BackgroundImage} alt="background" />
      <Hero>
        VODA
        <br />
        AI MACHINE
        <br />
        VISION PLATFORM
      </Hero>
        <Link to={'/contact'}>
            <CustomButton>{t('company')}</CustomButton>
        </Link>

    </Root>
  );
}
