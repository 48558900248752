import React from 'react';
import Chart from "../Chart";
import Chart2 from "../Chart2";
import Chart3 from "../Chart3";
import {MobileTitleBar, Wrapper} from "../index";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const MobileWith = () => {
    const {t, i18n} = useTranslation();
    return (
        <div>
            <MobileTitleBar>
                <div className={'grayBox'} id={'right'}>
                    <div className={'textBox'} >


                        {
                            i18n.language === 'en-US' ?
                                <>
                                    With <span className={'vodaText'}>V<span className={'blue'}>O</span>DA</span>
                                </>
                                :
                                <>
                                    <span className={'vodaText'}>V<span className={'blue'}>O</span>DA</span> 도입효과
                                </>
                        }
                        
                       
                    </div>
                    <div className={'blackMiniBox'}></div>
                </div>
            </MobileTitleBar>

            <Wrapper>
                <ChartBoxstyled>
                    <div className={'chartBox'}>
                        <div>
                            <div className={'title'}>{t('mo.productivity')}</div>
                            <Chart per={'30'}/>
                        </div>
                        <div className={'contents'}>


                            {
                                i18n.language === 'en-US' ?
                                    <>
                                        By high-accuracy inspection and remote monitoring, VODA is able to
                                        <span> immediately respond</span> to inspection
                                        troubles and <span>raises
                        automation rate</span> of factories.
                                    </>
                                    :
                                    <>
                                        <span>높은 정확도</span>의 품질검사와
                                        <br/>
                                        원격모니터링으로 자동화율 향상 및
                                        <br/>
                                         검사 이슈 <span>즉각 대응</span> 가능
                                    </>
                            }


                        </div>
                    </div>
                </ChartBoxstyled>

                <ChartBoxstyled>
                    <div className={'chartBox'}>
                        <div>
                            <div className={'title'}>{t('mo.insAccuracy') || ""}</div>
                            <Chart2 per={'99.9'} />
                        </div>
                        <div className={'contents'}>


                            {
                                i18n.language === 'en-US' ?
                                    <>
                                        Compared to the existing method, the accuracy is improved by <span>+15% </span>
                                        and raises <span>credibility </span>
                                        on inspection facility.

                                    </>
                                    :
                                    <>
                                        기존 대비 평균 <span>15%</span> 이상 개선으로
                                        <br/>
                                        검사 장비에 대한 <span>신뢰도</span> 향상
                                    </>
                            }


                        </div>
                    </div>
                </ChartBoxstyled>

                <ChartBoxstyled>
                    <div className={'chartBox'}>
                        <div>
                            <div className={'title'}>{t('mo.cost') || ""}</div>
                            <Chart3 per={'23'} />
                        </div>
                        <div className={'contents'}>

                            {
                                i18n.language === 'en-US' ?
                                    <>
                                        VODA helps <span>eliminate unnecessary steps</span> <br/>(e.g., re-assembly) by improvement of production efficiency.

                                    </>
                                    :
                                    <>
                                        작업 효율성 향상으로
                                        <br/>
                                        재조립 등의 <span>불필요한 과정</span> 감소
                                    </>
                            }
                        </div>
                    </div>
                </ChartBoxstyled>
            </Wrapper>
        </div>
    );
};

export default MobileWith;


export const ChartBoxstyled = styled.div`
  display: flex;
  max-width: 100%;
  .chartBox{
    text-align: center;
  }
  .title{
    font-size: 1.3rem;
    text-align: center;
    font-weight: 600;
    margin-top: 3rem;
    margin-bottom: 2rem;
    border-bottom: 2px solid #57dab8;
    border-top: 2px solid #57dab8;
    background:rgba(199,239,225,0.3) ;
    padding:0 1rem;
    
  }
  .contents{
    margin: 2rem auto 0 auto;
    width: 15rem;
    box-shadow: 2px 2px 10px 2px rgba(191,191,191,0.5);
    padding: 1rem;
    border-radius: 10px;
    border: solid 1px rgba(191,191,191,0.2);
    span{
      color: #23b99e;
      font-weight: 600;
    }
  }
`