import React from 'react';
import newPic from "../mobilesvg/new.png";
import map from "../mobilesvg/map.png";
import {MobileTitleBar, Wrapper} from "../index";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import ko_service from "../mobilesvg/ko_service.png";
import ko_map from "../mobilesvg/ko_map.png";
const ServiceImgStyled = styled.div`
  margin-top:2rem;
  display: flex;
  flex-direction: column;
  img{
    width:90%;
    background: rgba(240,255,252);
    border-radius: 15px;
    padding: 1rem;
    margin: 1rem auto;
    
  }
`
const MobileService = () => {
    const {t, i18n} = useTranslation();
    return (
        <div>

            <MobileTitleBar>
                <div className={'grayBox'} id={'right'}>
                    <div className={'textBox'} >
                        {
                            i18n.language === 'en-US' ?
                                <>
                                    <span className={'blue'}>S</span>ervice                                </>
                                :
                                <>
                                    제공 서비스
                                </>
                        }

                    </div>
                    <div className={'blackMiniBox'}></div>
                </div>
            </MobileTitleBar>

            <Wrapper>
                <ServiceImgStyled>

                    {
                        i18n.language === 'en-US' ?
                            <>
                                <img src={newPic} alt={'map'}/>
                                <img src={map} alt={'map'} />
                            </>
                            :
                            <>
                                <img src={ko_service} alt={'map'}/>
                                <img src={ko_map} alt={'map'} />
                            </>
                    }



                </ServiceImgStyled>
            </Wrapper>
        </div>
    );
};

export default MobileService;