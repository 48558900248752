import React from 'react';
import styled from "styled-components";
import { Paper } from "@material-ui/core";
import ScopeBoxImage from './img/ScopeBox.svg';
import { PageProps } from "./index";
import {useTranslation} from "react-i18next";
import ScopeBoxDraft from './DraftEng/draftImg/ScopeBox.svg';

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 75%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 3rem;


`;

const ContentsBox= styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const ScopeTextBox = styled.div`
  background-color: ${props => props.theme.palette.secondary.light};
  height: 13rem;
  width: 50rem;
  text-align: right;
  box-sizing: border-box;
  padding: 3rem;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.05);
  position: relative;
`


const Bar = styled.div`
  position: absolute;
  right: calc(50vw - 700px);
  top: 30px;
  z-index: 1;
  height: .75rem;
  width: 4rem;
  background-color: #2E323C;
`;


const ScopeBox = styled.div`
  font-size: 2.2rem;
  font-weight: 800;
`;

const ScopeBoxDescription = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
`;

const LeftCard = styled(Paper)`
  position:relative;
  z-index: 1;
  right: -130px;
  width: 550px;
  height: 160px;
  padding: 0 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.05);
  & h3 {
    margin-top: 0;
    margin-bottom: 12px;
    font-size: 1.25rem;
    font-weight: 600;
  }
  & p {
    font-weight: 400;
    margin: 0;
    line-height: 1.8;
    font-size: .9rem;
  }
  & span {
    color: ${props => props.theme.palette.secondary.main};
  }
`;

const DeepLearningBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const DeepLearningContents = styled.div`
  margin-left: 3rem;
  box-sizing: border-box;
`

const ImageBox = styled.div`
   border: 5px solid ${props => props.theme.palette.secondary.light};
   width: 40rem;
   height: 22rem;
   display: flex;
   align-items: center;
   justify-content: center;
`;

const DescriptionBox = styled.div`
  display: flex;
  flex-direction: column;


  & > h3 {
    font-size: 1.25rem;
  }
`;

const TopDescriptionBox = styled(DescriptionBox)`
  h3{
  margin-bottom: 0.5rem;
}
`;

const BottomDescriptionBox = styled(DescriptionBox)`
margin-top: 3rem;
  h3{
    margin-bottom: 0.5rem;
  }
`;


export default function Page3(props: PageProps) {
    const {t, i18n} = useTranslation();
  return (
    <Root>
        <ContentsBox>
            <Bar />
            <LeftCard square>
                <h3>{t('service_deep.nav') || ""}</h3>
                <p>
                    {t('service_deep.nav_desc')|| ""}
                    <br />
                    {t('service_deep.nav_desc_2')|| ""}
                </p>
            </LeftCard>

            <ScopeTextBox>
                <ScopeBox>Deep-learning Inspection
                    <br/>on Manufactures</ScopeBox>

                {
                    i18n.language === 'en-US' ?
                        null
                        :
                        <ScopeBoxDescription>공정 딥러닝 검사</ScopeBoxDescription>

                }
            </ScopeTextBox>
        </ContentsBox>


        <DeepLearningBox>
            <ImageBox>
                {
                    i18n.language === 'en-US' ?
                        <img src={ScopeBoxDraft} alt="scope box" />
                        :
                        <img src={ScopeBoxImage} alt="scope box" />

                }

            </ImageBox>

            <DeepLearningContents>
                <TopDescriptionBox>
                    <h3>Deep Learning</h3>
                    <p>
                        {t('service_deep.gpu')|| ""}
                        <br />
                        {t('service_deep.connect')|| ""}
                        <br />
                        <br />
                        {t('service_deep.few')|| ""}
                    </p>
                </TopDescriptionBox>

                <BottomDescriptionBox>
                    <h3>Data Platform</h3>
                    <p>
                        {t('service_deep.realtime')|| ""}
                        <br />
                        <br />
                        {t('service_deep.data')|| ""}
                        <br />
                        {t('service_deep.classification')|| ""}
                        <br />
                        <br />
                        {t('service_deep.update')|| ""}
                        <br />
                        {t('service_deep.non_contact')|| ""}
                    </p>
                </BottomDescriptionBox>
            </DeepLearningContents>
        </DeepLearningBox>


    </Root>
  );
}
