import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from "@material-ui/core";
import styled from "styled-components";
import {useTranslation} from "react-i18next";


const P = styled.p`
  margin: 16px 0;
`;

const H3 = styled.h3`
  margin: 17px 0 7px 0;
  font-weight: 400;
  font-size: 1rem;
`;

const S = styled.div`
`
interface PPDialogProps extends DialogProps {
    onAgree: () => void;
}


export default function MarketingDialog(props: PPDialogProps) {
    const { onAgree, onClose } = props;
    const {t} = useTranslation();
    return (
        <Dialog
            {...props}
        >
            <DialogTitle>
                <strong>{t('ppdial.marketing')}</strong>
            </DialogTitle>
            <DialogContent dividers>
                <S>
                    주식회사 보다에이아이 (이하 ‘회사’) 는 개인정보보호법, 정보통신망이용촉진 및 정보보호 등에 관한 법률과 같은 관계 법령에 따라 광고성 정보를 전송하기 위해 이용자의 사전 수신 동의를 받고 있습니다.
                </S>
                <P>
                    이용자는 수신 동의 이후에 언제든지 동의를 철회할 수 있으며 회사가 제공하는 서비스를 제공받으실 수 있습니다.
                </P>
                <P>
                    다만, 수신에 동의하지 않으실 경우 당사의 제품/서비스와 관련한 자료, 소식 등의 마케팅 정보를 제공받으실 수 없습니다.
                </P>


                <H3><strong>가. 전송방법</strong></H3>
                <S>
                    - 이메일, SMS, 전화
                </S>

                <H3><strong>나. 전송내용</strong></H3>

                <S>
                    - 발송되는 정보는 회사의 제품 및 서비스와 관련한 정보, 각종 이벤트, 프로모션, 신규 제품/서비스 관련 소식 등의 광고성 정보로 관련 법을 준수하여 발송됩니다.
                    단, 광고성 정보 이외에 의무적으로 안내되어야 하는 정보성 내용은 수신동의 여부와 관계없이 제공됩니다.
                </S>

                <H3><strong>다. 수집항목</strong></H3>

                <S>
                    - 성명, 직업, 소속/회사, 부서, 직위/직책, 이메일 주소, 휴대폰번호/사무실번호
                </S>

                <H3><strong>다. 이용목적</strong></H3>

                <S>
                    - 회사 소개 자료 제공, 신규 제품/서비스 소개 자료 제공, 이벤트, 마케팅 등 광고성 정보 제공
                </S>


                <H3><strong>마. 수신동의 철회 및 보유기간</strong></H3>

                <S>
                    - 마케팅 정보 수신 동의일로부터 3년, 기간 초과 시 동의 절차 재 진행. (전송되는 이메일 하단의 ‘수신거부’를 클릭하거나 회사로 수신거부 의사 전달, 또는 미동의 시 지체없이 개인정보 파기)
                </S>


            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose && onClose({}, 'escapeKeyDown')} style={{ opacity: 0.7 }}>
                    {t("ppdial.exit")}
                </Button>
                <Button onClick={onAgree} style={{ color: '#23B99E' }}>
                    {t("ppdial.agree")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
