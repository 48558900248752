import { Route, Switch, BrowserRouter} from "react-router-dom";
import React from 'react';
import About from "../pages/About";
import NotFound from "../pages/NotFound";
import ContactUs from "../pages/ContactUs";
import { useMediaQuery, useTheme } from "@material-ui/core";
import MobileMaintenance from "../pages/MobileMaintenance";
import SendInfo from "../pages/SendInfo";
import PrivacyPage from "../pages/PrivacyPage";

const Router = () => {
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('sm'));

  // const { isSuccess, data } = useUserQuery(false);
  // const user: UserType | undefined = data?.data;
  // const authenticated = isSuccess && !!user;
  // const isAdmin = authenticated && !!user && user.is_superuser;
  // const isStaff = authenticated && !!user && (user.is_staff || user.is_superuser);

  return (
    <BrowserRouter>
      {mdDown ? (
        <Switch>
          <Route component={MobileMaintenance} />
        </Switch>
      ) : (
        <Switch>
          <Route exact path="/" component={About} />
          <Route exact path="/contact" component={ContactUs} />
          <Route exact path="/sendinfo" component={SendInfo} />
          <Route exact path="/privacy" component={PrivacyPage} />
          <Route component={NotFound} />
        </Switch>
      )}
    </BrowserRouter>
  )
};

export default Router;
