import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";


const P = styled.p`
  margin: 16px 0;
`;

const SP = styled.p`
  margin: 16px 0 0 15px;
`;

const H1 = styled.h1`
margin-bottom: 25px;
`

const H3 = styled.h3`
  margin: 17px 0 7px 0;
  font-weight: 400;
  font-size: 1rem;
`;

const H4 = styled.div`
  margin: 5px 0 5px 0;
  font-size: 0.9rem;
`

const S = styled.div`
`

const SSP = styled.p`
  margin-bottom: 6px;
`
const SSSP = styled.p`
  margin-bottom: 3px;
  margin-left: 5px;
`

const DIV = styled.div`
padding:30px;
`

export default function PrivacyPage() {
    const {t} = useTranslation();
    return (
    <DIV>
        <H1><strong>{t('global.voda_privacy')}</strong></H1>
        <S>
            주식회사 보다에이아이('https://www.vodavision.ai', 이하 'VODA')는 「개인정보 보호법」
        </S>
        <S>
            제30조에 따라 정보주체의 개인정보를 보호하고
            이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
        </S>
        <P>
            ○ 이 개인정보처리방침은 2023년 08월 01부터 적용됩니다.
        </P>
        <P>
            <H3><strong>제1조(개인정보의 처리 목적)</strong></H3>
            <S>
                주식회사 보다에이아이('http://www.vodavision.ai', 이하 'VODA')는 다음의 목적을 위하여 개인정보를 처리합니다.
                처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」
                제18조에 따라 별도의 동의를 받는 등
                <S>필요한 조치를 이행할 예정입니다.</S>
            </S>

            <SP>
                <H4>1. 마케팅 및 광고에의 활용</H4>
                <S>
                    신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공,
                    인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계,
                    회사 소개 자료 제공, 신규 제품/서비스 소개 자료 제공, 이벤트, 마케팅 등 광고성 정보 제공 등을 목적으로 개인정보를 처리합니다.
                </S>
            </SP>
        </P>

        <H3><strong>제2조(개인정보의 처리 및 보유 기간)</strong></H3>
        <SSP>
            ① 주식회사 보다에이아이는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.
        </SSP>
        <SSP>
            ② 개인정보 처리 및 보유 기간은 다음과 같습니다.
        </SSP>

        <SP>
            <H4>1. 마케팅 및 광고에의 활용</H4>
            <SSSP>
                A. &lt;마케팅 및 광고에의 활용&gt;와 관련한 개인정보는 수집ㆍ이용에 관한 동의일로부터 &lt;3년&gt;까지 위 이용목적을 위하여 보유.이용됩니다.
            </SSSP>
            <SSSP>
                B. 보유근거: 개인정보보호법 제15조(개인정보의 수집ㆍ이용)
            </SSSP>
        </SP>

        <H3><strong>제3조(처리하는 개인정보의 항목)</strong></H3>
        <SSP>
            ① 주식회사 보다에이아이는 다음의 개인정보 항목을 처리하고 있습니다.
        </SSP>

        <SP>
            <H4>1. 마케팅 및 광고에의 활용</H4>
            <SSSP>
                A. 필수항목: 성명, 직업, 소속/회사, 부서, 직위/직책, 이메일 주소, 휴대폰번호/사무실번호
            </SSSP>
        </SP>

        <H3><strong>제4조(개인정보의 제3자 제공에 관한 사항)</strong></H3>
        <SSP>
            ① 주식회사 보다에이아이는 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며,
            정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에
            <SSP>해당하는 경우에만 개인정보를 제3자에게 제공합니다.</SSP>
        </SSP>

        <H3><strong>제5조(개인정보의 파기절차 및 파기방법)</strong></H3>
        <SSP>
            ① 주식회사 보다에이아이는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.</SSP>
        <SSP>
            ② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는,
            해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
        </SSP>
        <SSP>
            ③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
        </SSP>

        <SP>
            <H4>1. [파기절차]</H4>
            <SSSP>
                주식회사 보다에이아이는 파기 사유가 발생한 개인정보를 선정하고, 주식회사 보다에이아이의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
            </SSSP>

            <H4>2. [파기방법]</H4>
            <SSSP>
                종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
                전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다
            </SSSP>
        </SP>

        <H3><strong>제6조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)</strong></H3>
        <SSP>
            ① 정보주체는 주식회사 보다에이아이에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
        </SSP>
        <SSP>
            ② 제1항에 따른 권리 행사는 주식회사 보다에이아이에 대해 「개인정보 보호법」 시행령 제41조
            <br />제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 주식회사 보다에이아이는 이에 대해 지체 없이 조치하겠습니다.
        </SSP>
        <SSP>
            ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에
            <br/>따른 위임장을 제출하셔야 합니다.
        </SSP>
        <SSP>
            ④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.
        </SSP>
        <SSP>
            ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
        </SSP>
        <SSP>
            ⑥ 주식회사 보다에이아이는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
        </SSP>

        <H3><strong>제7조(개인정보의 안전성 확보조치에 관한 사항)</strong></H3>
        <SSP>
            주식회사 보다에이아이는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
        </SSP>

        <SP>
            <H4>1.	[개인정보 취급 직원의 최소화 및 교육]</H4>
            <SSSP>
                개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.
            </SSSP>

            <H4>2.	[개인정보에 대한 접근 제한]</H4>
            <SSSP>
                개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며
                침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
            </SSSP>
        </SP>


        <H3><strong>제8조(개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항)</strong></H3>
        <SSP>
            주식회사 보다에이아이는 정보주체의 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다. 쿠키는
            웹 사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며
            이용자들이 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
        </SSP>
        <SP>
            <H4>1. 쿠키의 사용목적</H4>
            <SSP>이용자의 접속빈도나 방문시간 등을 파악하여 이용자에게 보다 편리한 서비스를 제공하기 위해 사용됩니다.</SSP>

            <H4>2. 쿠키의 설치·운영 및 거부</H4>
            <SSP>브라우저 옵션 설정을 통해 쿠키 허용, 쿠키 차단 등의 설정을 할 수 있습니다.</SSP>

            <SSSP>A. Internet Explorer</SSSP>
            <SSSP>웹브라우저 우측 상단의 도구 메뉴 &gt; 인터넷 옵션 &gt; 개인정보 &gt; 설정 &gt; 고급</SSSP>

            <SSSP>B. Edge</SSSP>
            <SSSP>웹브라우저 우측 상단의 설정 메뉴 &gt; 쿠키 및 사이트 권한 &gt; 쿠키 및 사이트 데이터 관리 및 삭제</SSSP>

            <SSSP>C. Chrome</SSSP>
            <SSSP>웹브라우저 우측 상단의 설정 메뉴 &gt; 개인정보 및 보안 &gt; 쿠키 및 기타 사이트 데이터</SSSP>

            <H4>3.	쿠키 저장을 거부 또는 차단할 경우 서비스 이용에 어려움이 발생할 수 있습니다.</H4>
        </SP>
        <H3><strong>제9조 (개인정보 보호책임자에 관한 사항)</strong></H3>
        <SSP>
            ① 주식회사 보다에이아이는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이
            개인정보 보호책임자를 지정하고 있습니다.
        </SSP>

        <SP>
            <H4>[▶ 개인정보 보호 책임자 및 담당자]</H4>
            <H4>책임자: 주식회사 보다에이아이 대표이사</H4>
            <H4>연락처: 070-4617-1810</H4>
            <H4>이메일: ceo@vodavision.ai</H4>
        </SP>


        <H3><strong>제11조(정보주체의 권익침해에 대한 구제방법)</strong></H3>
        <SSP>
            정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회,
            한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다.
            이 밖에 기타 개인 정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
        </SSP>

        <SP>
            1. 개인정보분쟁조정위원회: (국번없이) 1833-6972 (www.kopico.go.kr)<br/>
            2. 개인정보침해신고센터: (국번없이) 118 (privacy.kisa.or.kr)<br/>
            3. 대검찰청: (국번없이) 1301 (www.spo.go.kr)<br/>
            4. 경찰청: (국번없이) 182 (ecrm.cyber.go.kr)<br/>
        </SP>
        <SP>
            「개인정보보호법」 제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에
            의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는
            이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.
            <br/> <div style={{color:"grey", fontSize:'13px', marginTop:"5px"}}>※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.</div>
        </SP>

        <H3><strong>제12조(개인정보 처리방침 변경)</strong></H3>
        <SSP>
            ① 이 개인정보처리방침은 2023년 8월 1부터 적용됩니다.
        </SSP>
        <SSP>
            ② 이전의 개인정보 처리방침은 없습니다.
        </SSP>
    </DIV>

    )
};
