import React from 'react';
import styled from "styled-components";
import { Paper } from "@material-ui/core";
import VideoFile from './img/video.mp4';
import { PageProps } from "./index";


const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const LeftCard = styled.div`
  position: absolute;
  background-color: ${props => props.theme.palette.secondary.light};
  top: 300px;
  left: 0;
  width: calc(50vw + 100px);
  height: 550px;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.05);
  z-index: 1;
  opacity: 0.4;
  
  @keyframes fade-slide-in-left {
    from {
      transform: translateX(-50%);
      opacity: 0.4;
    }
    to {
      transform: translateX(0);
      opacity: 0.8;
    }
  }
  &.active {
    opacity: 0.8;
    animation: fade-slide-in-left 1.2s;
  }
`;

const Bar = styled.div`
  position: absolute;
  left: calc(50vw - 664px);
  top: 416px;
  height: 1rem;
  width: 5rem;
  background-color: #2E323C;
  z-index: 2;
  opacity: 0;
  
  @keyframes fade-slide-in-bottom-1 {
    from {
      transform: translateY(75%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 0.8;
    }
  }
  
  &.active {
    opacity: 0.8;
    animation: fade-slide-in-bottom-1 1.2s;
  }
`;

const ConnectBackground = styled.div`
  position: absolute;
  left: calc(50vw - 636px);
  top: 536px;
  background-color: #fff;
  width: 450px;
  height: 50px;
  z-index: 2;
  opacity: 0;
  
  @keyframes fade-slide-in-bottom-2 {
    from {
      transform: translateY(25%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 0.8;
    }
  }
  
  &.active {
    opacity: 0.8;
    animation: fade-slide-in-bottom-2 1.2s;
  }
`;

const Connect = styled.div`
  position: absolute;
  left: calc(50vw - 624px);
  top: 476px;
  font-size: 88px;
  font-weight: 600;
  z-index: 3;
  opacity: 0;
  
  @keyframes fade-slide-in-bottom-3 {
    from {
      transform: translateY(25%);
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 0.8;
    }
  }
  
  &.active {
    opacity: 0.8;
    animation: fade-slide-in-bottom-3 1.2s;
  }
`;

const MachineVision = styled.div`
  position: absolute;
  left: calc(50vw - 624px);
  top: 608px;
  font-size: 56px;
  font-weight: 600;
  color: ${props => props.theme.palette.secondary.main};
  z-index: 3;
  opacity: 0;
  
  @keyframes fade-slide-in-bottom-4 {
    from {
      transform: translateY(25%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 0.8;
    }
  }
  &.active {
    opacity: 0.8;
    animation: fade-slide-in-bottom-4 1.2s;
  }
`;

const RightCard = styled(Paper)`
  position: absolute;
  top: 424px;
  left: calc(50vw + 16px);
  width: 680px;
  padding: 2.75rem 6rem;
  font-size: 28px;
  font-weight: 600;
  & span {
    color: ${props => props.theme.palette.secondary.main};
  }
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.05);
  z-index: 4;
  opacity: 0.4;
  
  @keyframes fade-slide-in-right {
    from {
      transform: translateX(10%);
      opacity: 0.4;
    }
    to {
      transform: translateX(0);
      opacity: 0.8;
    }
  }
  
  &.active {
    opacity: 0.8;
    animation: fade-slide-in-right 1.2s;
  }
  
  & > p {
    opacity: 0;
  }
  
  &.active > p {
    opacity: 0.8;
    animation: fade-slide-in-right 1.4s;
  }
`;

const Video = styled.video`
  position: relative;
  left: 0;
  top: 0;
  object-position: left center;
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-height: 100%;
  z-index: -1;
  opacity: 0.5;
`;


export default function Page1(props: PageProps) {
  const { active } = props;

  return (
    <Root>
      <Video autoPlay muted loop onPause={
        (e) => {
          e.currentTarget.play();
        }}
      >
        <source src={VideoFile} type="video/mp4" />
      </Video>

      <LeftCard className={active ? 'active' : ''} />
      <Bar className={active ? 'active' : ''} />
      <ConnectBackground className={active ? 'active' : ''} />
      <Connect className={active ? 'active' : ''}>CONNECT</Connect>
      <MachineVision className={active ? 'active' : ''}>Machine Vision</MachineVision>

      <RightCard className={active ? 'active' : ''} square>
        <p>
          Your <span>product</span> quality
          <br />
          Your <span>performance</span> quality
          <br />
          Your <span>life</span> quality
          <br />
          <br />
          Upgrade with <strong>V<span>O</span>DA</strong>
        </p>
      </RightCard>
    </Root>
  );
}