import React from 'react';
import styled from "styled-components";
import { Paper } from "@material-ui/core";
import ScopeInputImage from './img/ScopeInput.svg';
import { PageProps } from "./index";
import {useTranslation} from "react-i18next";
import ScopeInputImageDraft from "../About/DraftEng/draftImg/ScopeInput.svg";

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 3rem;

`;

const ContentsBox= styled.div`
  display: flex;
  justify-content: center;
  position: relative;
 margin-left: 24.5rem;
 
`

const ScopeTextBox = styled.div`
  display: flex;
`


const Bar = styled.div`
  position: absolute;
  left: calc(50vw - 890px);
  top: 50px;
  height: .75rem;
  width: 4rem;
  z-index: 1;
  background-color: #2E323C;
`;
const MonitoringBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const MonitoringContents = styled.div`
  margin-left: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 18rem;
  
`
const ScopeInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: ${props => props.theme.palette.secondary.light};
  height: 12rem;
  width: 50rem;
  text-align: left;
  align-content: center;
  padding: 3rem;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  font-size: 3rem;
  font-weight: 800;
  margin-top: 1rem;
`;

const ScopeInputDescription = styled.div`
  font-size: 1.25rem;
  font-weight: 400;
`;

const RightCard = styled(Paper)`
  position: relative;
  right: 13rem;
  align-content: center;
  width: 530px;
  height: 180px;
  padding: 0 0 0 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1.5rem;
  text-align: left;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.05);
  & h3 {
    margin-top: 0;
    margin-bottom: 12px;
    font-size: 1.25rem;
    font-weight: 600;
  }
  & p {
    font-weight: 400;
    margin: 0;
    line-height: 1.75;
    font-size: .9rem;
  }
  & span {
    color: ${props => props.theme.palette.secondary.main};
  }
`;

const ImageBox = styled.div`
  border: 5px solid ${props => props.theme.palette.secondary.light};
  width: 40rem;
  height: 25rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DescriptionBox = styled.div`
  & > h3 {
    font-size: 1.25rem;
  }
`;

const TopDescriptionBox = styled(DescriptionBox)`
  p{
    margin-top: 0.5rem;
  }
`;

const MiddleDescriptionBox = styled(DescriptionBox)`
  p{
    margin-top: 0.5rem;
  }
`;

export default function Page4(props: PageProps) {
    const {t, i18n} = useTranslation();
  return (
    <Root>
      <ContentsBox>
      <Bar />
          <ScopeTextBox>
      <ScopeInput>
          <div>Monitoring Service</div>
          {
              i18n.language === 'en-US' ?
                  null
                  :
                  <ScopeInputDescription>공정 관제 서비스</ScopeInputDescription>
          }
      </ScopeInput>

      <RightCard square>
        <p>
            {t('monitoring_service.collect') || ""}
          <br />
            {t('monitoring_service.dashboard') || ""}
          <br />
          <br />
            {t('monitoring_service.solution') || ""}
          <br />
            {t('monitoring_service.connect') || ""}
        </p>
      </RightCard>
          </ScopeTextBox>
      </ContentsBox>

        <MonitoringBox>
            <ImageBox>
                {
                    i18n.language === 'en-US' ?
                        <img src={ScopeInputImageDraft} alt="scope input" />
                        :
                        <img src={ScopeInputImage} alt="scope input" />

                }

            </ImageBox>

            <MonitoringContents>
                <TopDescriptionBox>
                    <h3>  {t('monitoring_service.monitoring') || ""}</h3>
                    <p>
                        {t('monitoring_service.status') || ""}
                        <br />
                        {t('monitoring_service.rate') || ""}
                    </p>
                </TopDescriptionBox>

                <MiddleDescriptionBox>
                    <h3> {t('monitoring_service.factor') || ""}</h3>
                    <p>
                        {t('monitoring_service.check') || ""}
                        <br />
                        {t('monitoring_service.visualize') || ""}
                    </p>
                </MiddleDescriptionBox>
            </MonitoringContents>
        </MonitoringBox>
    </Root>
  );
}
