import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from "@material-ui/core";
import styled from "styled-components";
import {useTranslation} from "react-i18next";


const P = styled.p`
  margin: 16px 0;
`;

const H3 = styled.h3`
  margin: 17px 0 7px 0;
  font-weight: 400;
  font-size: 1rem;
`;

const S = styled.div`
`


interface PPDialogProps extends DialogProps {
    onAgree: () => void;
}


export default function PPDialog(props: PPDialogProps) {
    const { onAgree, onClose } = props;
    const {t} = useTranslation();
    return (
        <Dialog
            {...props}
        >
            <DialogTitle>
                <strong>{t('ppdial.privacy')}</strong>
            </DialogTitle>
            <DialogContent dividers>
                <S>
                    주식회사 보다에이아이 (이하 ‘회사’)는 비즈니스 정보의 전달을 위해 이용자로부터 아래와 같이 개인정보를 수집하고 있습니다.
                </S>
                <P>
                    이용자는 본 개인정보 수집 및 이용 동의서에 동의하지 아니할 권리가 있습니다.
                </P>
                <P>
                    개인정보 처리에 대한 상세한 사항은 회사의 공식 사이트 (https://www.vodavision.ai)에 공개된 “개인정보 처리방침”을 참조하십시오.</P>


                <P>
                    다만, 본 동의서의 내용과 상충되는 사항이 있을 시 본 동의서의 내용이 우선됩니다.
                </P>


                <H3><strong>가. 개인정보 수집 및 이용 목적</strong></H3>
                <S>
                    - 회사 소개 자료 제공, 신규 제품/서비스 소개 자료 제공, 이벤트, 마케팅 등 광고성 정보 제공
                </S>

                <H3><strong>나. 개인정보 수집항목</strong></H3>

                <S>
                    - 성명, 직업, 소속/회사, 부서, 직위/직책, 이메일 주소, 휴대폰번호/사무실번호
                </S>

                <H3><strong>다. 개인정보 보유 및 이용 기간</strong></H3>

                <S>
                    제공일로부터 3년, 기간 초과 시 동의 절차 재 진행. (전송되는 이메일 하단의 ‘수신거부’를 클릭하거나 회사로 수신거부 의사 전달, 또는 미동의 시 지체없이 개인정보 파기)
                </S>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose && onClose({}, 'escapeKeyDown')} style={{ opacity: 0.7 }}>
                    {t("ppdial.exit")}
                </Button>
                <Button onClick={onAgree} style={{ color: '#23B99E' }}>
                    {t("ppdial.agree")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
