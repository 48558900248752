import React from 'react';
import styled from "styled-components";
import { PageProps } from "./index";
import {useTranslation} from "react-i18next";
import ReactPlayer from "react-player";
import {videoURL_ko, videoURL_en} from "../VideoLink";

const Root = styled.div`
  width: 50%;
  .videoTitle {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 25px;
    color: rgb(61, 61, 61);
    .alp_o{
      color: #23b99e;
    }
  }

  padding: 20px;
  margin: auto;
  .player-wrapper {
    position: relative;
    padding-top: 56.25%;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const VideoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`


export default function Page2(props: PageProps) {
    const { t, i18n } = useTranslation();

    return (
   <>
       {
           i18n.language === 'en-US' ?
               <VideoBox>
                   <Root>
                       <div className={'videoTitle'}>{t("video.voda_does")}{" "}V<span className={'alp_o'}>O</span>DA{" "}{t("video.voda_do")}</div>
                       <div className='player-wrapper'>
                           <ReactPlayer
                               url={videoURL_en}
                               className='react-player'
                               controls={true}
                               muted={true}
                               width='100%'
                               height='100%'
                           />
                       </div>
                   </Root>
               </VideoBox>

               :
               <VideoBox>
                   <Root>
                       <div className={'videoTitle'}>V<span className={'alp_o'}>O</span>DA{t("video.voda")}</div>
                       <div className='player-wrapper'>
                           <ReactPlayer
                               url={videoURL_ko}
                               className='react-player'
                               controls={true}
                               muted={true}
                               width='100%'
                               height='100%'
                           />
                       </div>
                   </Root>
               </VideoBox>
       }
   </>


    );
}
